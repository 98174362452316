<template>
  <div class="no-select">
    <div v-if="checkInStatus == false">
      <v-row>
        <v-col cols="12" sm="5">
          <v-container>
            <getRescheduleTime :eventId="selectedEvent.eventId" />

            <patientInformationDisplay v-if="oneUser.UserId" :selectedEvent="oneUser" :key="oneUser.UserId" />

            <v-card outlined v-if="!selectedEvent.missed">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-card-title>Edit Services</v-card-title>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <template>
                      <v-data-table :headers="ProviderServiceHeaders" :items="providerServicePairs" hide-default-header
                        hide-default-footer show-expand class="elevation-1">
                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                            Note : {{ item.service.perVisitDetails }}
                          </td>
                        </template>
                        <template v-slot:[`item.actions`]="{ item, index }">
                          <v-icon small class="mr-2" v-if="index !== 0" @click="deleteTimeSlot(item)">mdi-delete</v-icon>
                        </template>
                      </v-data-table>
                    </template>
                    <br />
                    <v-card>
                      <v-container>
                        <providerSelector @providerSelected="onProviderSelected" :locationId="locationId"
                          :key="locationId" />

                        <serviceSelector @serviceSelected="onServiceSelected" :providerId="providerId"
                          :locationId="locationId" :key="providerId + '-' + locationId" />

                        {{ timeSlotLengthToDisplay }}

                        <v-text-field v-model="perVisitDetails" label="Per Visit Details" outlined dense></v-text-field>

                        <v-btn @click="addServedList" text color="primary">
                          Add This New Service
                        </v-btn>
                      </v-container>
                    </v-card>

                    <v-card-actions class="justify-center">
                      <v-btn text color="primary" @click="saveEvent()">
                        Save</v-btn>
                    </v-card-actions>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-card-title>Reschedule Appointment</v-card-title>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" sm="7">


                        <v-select v-model="locationChange" :item-text="'companyName'" :item-value="'companyName'"
                          :items="locationsList" label="Location" dense outlined @change="fetchLocationEvents($event)"
                          return-object></v-select>

                        <br />
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-btn color="primary" class="mr-4" @click="changeLocation">Change</v-btn>
                      </v-col>
                    </v-row>

                    <v-text-field v-model="today" type="date" @input="getDayEventByDateSelection(today)"
                      label="Date (Year/Month/Day)" />

                    <br />

                    <v-btn color="primary" class="mr-4" @click="rescheduleEvent()">Change Time</v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>




                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-card-title>Actions</v-card-title>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>


                    <br />

<!-- 
                    <div>
                      <v-btn text @click="sendEmailReminder()"> Send Email Reminder</v-btn>
                    </div> -->


                    <!-- <div>
                      <v-btn text @click="sendTextReminder()">Send Phone Reminder </v-btn>
                    </div> -->


                    <v-btn text color="red" @click="cancelEvent()">Cancel Event </v-btn>

                    <br>
                    <v-btn text color="red" @click="missedEvent()"> Missed </v-btn>

                    <v-icon small class="mr-2" @click="deleteEvent(item)" style="float: right;"
                      color="red">mdi-delete</v-icon>

                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>





              <v-card-actions class="justify-center">
                <v-btn text @click="lateEvent()">Late </v-btn>
                <v-btn text color="primary" @click="checkInEvent()">
                  Check In
                </v-btn>
                <v-snackbar v-model="snackbar" :timeout="3000" color="success">
                  Missed Event Confirmed!
                  <v-btn color="white" text @click="snackbar = false">Close</v-btn>
                </v-snackbar>

                <v-snackbar v-model="loading" :timeout="-1" color="info">
                  Loading...
                </v-snackbar>
              </v-card-actions>
            </v-card>

            <v-card outlined v-if="selectedEvent.missed">This is an missed event</v-card>

          </v-container>
        </v-col>
        <v-col cols="12" sm="7">
          <v-calendar ref="calendar" v-model="today" type="category" :events="events" :now="today" :value="today"
            :categories="categoryName" :first-interval="6" :interval-minutes="60" interval-height="70"
            :interval-count="18" @mousedown:event="startDrag" @mousemove:time="mouseMove" @mouseup:time="endDrag"
            @mousedown:time-category="startTimeDayCategory" @mousemove:time-category="mouseMoveTimeCategory"
            @mouseleave.native="cancelDrag">
            <template v-slot:event="{ eventSummary }">
              <div class="v-event-draggable">
                <component :is="{ render: eventSummary }"></component>
              </div>
            </template>
          </v-calendar>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <br />
      <v-card>
        <v-container>
          <v-card-title>Events and Related Events</v-card-title>
          <v-card-text>
            <div v-for="(event, index) in allRelatedEvents" :key="index">
              Service {{ index + 1 }} : {{ event.provider[0].service[0].serviceName }} <br />
            </div>
          </v-card-text>
        </v-container>
      </v-card>
      <patientInformationDisplay v-if="oneUser.UserId" :selectedEvent="oneUser" :key="oneUser.UserId" />
      <v-container>
        <v-card class="mx-auto" outlined>
          <v-container>
            <v-card-title>Invoice & Receipt</v-card-title>
            <v-list-item>
              <v-btn @click="newReceiptPage = true, newReceiptCounter++">Receipt</v-btn>
              <v-spacer />
              <v-btn @click="invoiceOpen = true, invoiceCounter++">Invoice</v-btn>
              <br />
            </v-list-item>

          </v-container>

        </v-card>
      </v-container>
      <v-card-actions class="justify-center">
        <v-btn text color="secondary" @click="undoCheckInEvent()">
          Undo Check In
        </v-btn>
      </v-card-actions>
    </div>
 

    <v-dialog v-model="eventLocationChangedDialog" max-width="290" persistent>
      <v-card>
        <v-card-title>Event Location Changed, Press OK to go to the new
          Location</v-card-title>
        <v-card-actions>
          <v-btn text @click="confirmAndReload">OK</v-btn>
          <v-btn text @click="reload">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="invoiceOpen" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <h3>Invoice & Receipt Management Page</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="invoiceOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <invoicePage :selectedEvent="selectedEvent" :key="invoiceCounter" />
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="newReceiptPage" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <h3>Invoice & Receipt Management Page</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="newReceiptPage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <receiptPage :selectedEvent="selectedEvent" @close="newReceiptPage = false" :key="newReceiptCounter" />
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import 'moment-timezone';
export default {
  name: "dailyAppointmentDetailPage",
  props: ["selectedEvent", "checkInStatus"],
  data: () => ({
    changedEvent: null,
    oneUser: [],
    value: "",
    events: [],
    colors: [
      "#2196F3",
      "#3F51B5",
      "#673AB7",
      "#00BCD4",
      "#4CAF50",
      "#FF9800",
      "#757575",
    ],
    dragEvent: null,
    invoiceCounter: 0,
    newReceiptCounter: 0,
    newReceiptPage: false,
    invoiceOpen: false,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    providerId: null,
    perVisitDetails: null,
    providerServicePairs: [],
    eventLocationChangedDialog: false,
    singleExpand: true,
    locationsList: [],
    locationChange: null,
    ProviderServiceHeaders: [
      {
        text: "Provider",
        align: "start",
        value: "provider.providerName",
      },
      { text: "Service", value: "service.serviceName" },
      { text: "Time", value: "service.difference" },
      { text: "Actions", value: "actions", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    loading: false,
    snackbar: false,
    categoryName: [],
    eventLocation: [],

    locationId: null,
    today: new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    providerAdd: null,
    serviceAdd: null,
    service_type_dropdown: [],
    provider_name_dropdown: [],
    timeSlotLengthToDisplay: "",
    doctor_list_dropdown: [],
    allRelatedEvents: [],
  }),

  async mounted() {

    this.locationId = this.selectedEvent.location.location.locationId;
    await this.getUserInfo(this.selectedEvent.UserId);
    this.selectedEvent.provider.forEach((doc) => {
      doc.service[0].difference = this.getTimeLengthDifference(
        this.selectedEvent.end - this.selectedEvent.start
      );
      this.categoryName.push(doc.provider.providerName);

      this.providerServicePairs.push({
        provider: doc.provider,
        service: doc.service[0],
      });
    });
    this.today = new Date(this.selectedEvent.start).toISOString().substr(0, 10);
    this.eventLocation = this.selectedEvent.location.location;
    this.locationChange = this.eventLocation
    this.getAllRelatedEvents()
    await this.updateDoctorList();
    this.getDayEvent();
    this.getAllLocationProviderWorks();
  },
  methods: { 
    async getReceiptList() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/receiptList/get",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          UserId: this.selectedEvent.UserId
        }
      })
        .then((response) => {
          this.receiptListItems = response.data
          this.receiptListItems.forEach((doc) => {
            doc.date = doc.date.split("T")[0] + " " + doc.date.split("T")[1].split('.')[0]
          })
        })
        .catch((error) => console.log(error));
    },
    async getAllRelatedEvents() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getAllRelatedEvents",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { selectedEvent: this.selectedEvent },
      })
        .then((doc) => {
          let appDataList = doc.data;
          let events = []
          appDataList.forEach((doc) => {
            // Extracting the required details
            events.push(doc)
          })
          this.allRelatedEvents = events
        }).catch((error) => console.log(error));
    },

    // async sendEmailReminder() {
    //   let name = this.oneUser.basicInfo.last_name
    //   let date = this.$moment(this.selectedEvent.start).format('YYYY-MM-DD')
    //   let startTime = this.$moment(this.selectedEvent.start).format('HH:mm')
    //   let endTime = this.$moment(this.selectedEvent.end).format('HH:mm')
    //   await this.$axios({
    //     method: "post",
    //     url: this.$axios.defaults.baseURL + "/api/sendEmail",
    //     headers: { Authorization: localStorage.getItem("accessToken") },
    //     data: {
    //       email: this.oneUser.defaultEmail,
    //       start: startTime,
    //       name: name,
    //       date: date,
    //       end: endTime,
    //       location: this.selectedEvent.location.location
    //     },
    //   }).then((doc) => {
    //     alert('Reminder Send!', doc)
    //   })
    //     .catch((error) => console.log(error));

    // },
    async sendTextReminder() {
      let name = this.oneUser.basicInfo.last_name
      let date = this.$moment(this.selectedEvent.start).format('YYYY-MM-DD')
      let startTime = this.$moment(this.selectedEvent.start).format('HH:mm')
      let endTime = this.$moment(this.selectedEvent.end).format('HH:mm')
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/twilioTextMessage",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          phoneNumber: this.oneUser.defaultPhoneNumber,
          start: startTime,
          name: name,
          date: date,
          end: endTime,
        }
      }).then(() => {
        alert('Reminder Sent!')
      })
        .catch((error) => console.log(error));
    },



    async deleteEvent() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/deleteEvent",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          event: this.selectedEvent
        },
      })
        .then(() => {
          location.reload()
        })
        .catch((error) => console.log(error));
    },

    async cancelEvent() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/cancelEvent",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          event: this.selectedEvent,
          status: "cancelled",
          first_name: this.selectedEvent.first_name,
          userId: this.selectedEvent.UserId,
        },
      })
        .then(() => {
          location.reload()
        })
        .catch((error) => console.log(error));
    },

    async lateEvent() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/checkInEvent/late",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          event: this.selectedEvent,
          location: this.eventLocation,
          eventId: this.selectedEvent.eventId,
          status: "late",
          first_name: this.selectedEvent.first_name,
          userId: this.selectedEvent.UserId,

        },
      })
        .then(() => {
          location.reload()
        })
        .catch((error) => console.log(error));
    },

    reload() {
      location.reload()
    },
    async fetchLocationEvents(data) {
      this.locationId = data.locationId;
      this.eventLocation = data;
      this.getDayEventByDateSelection(this.today)
    },
    async getAllLocationProviderWorks() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getDocLocationList",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          docId: this.selectedEvent.provider[0].provider.providerId,
        },
      })
        .then((doc) => {
          this.locationsList = []
          doc.data.forEach((location) => {
            this.locationsList.push(location.location)
          })
        })
        .catch((error) => console.log(error));
    },
    async updateDoctorList() {
      let doctors = [];
      await this.$axios({
        method: "post",
        url:
          this.$axios.defaults.baseURL + "/api/getProviderFilterWithLocation",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: this.selectedEvent.location.location.locationId,
        },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            doctors.push(appData);
          });
        })
        .catch((error) => console.log(error));
      this.doctor_list_dropdown = doctors;
    },
    getTimeLengthDifference(time) {
      let temp = time / 60000;
      let hours = Math.floor(temp / 60);
      let minutes = temp % 60;
      if (hours === 0) {
        return `${minutes} mins`;
      } else if (minutes === 0) {
        return `${hours} hr${hours > 1 ? "s" : ""}`;
      } else {
        return `${hours} hr${hours > 1 ? "s" : ""} ${minutes} mins`;
      }
    },
    confirmAndReload() {
      this.$emit("reloadLocation", this.eventLocation);
    },
    async undoCheckInEvent() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/unCheckInEvent",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          event: this.selectedEvent,
          userId: this.selectedEvent.UserId,
        },
      })
        .then(() => { })
        .catch((error) => console.log(error));
      location.reload();
    },
    async getUserInfo(event) {
      let user = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getOneUser",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { userId: event },
      })
        .then((response) => {
          let appData = response.data;
          user = appData;
        })
        .catch((error) => console.log(error));

      this.oneUser = user;

      this.searchUser = null;
      this.userSuggestionButtonList = [];
    },
    onProviderSelected(data) {
      this.providerId = data.providerId;
      this.timeSlotLengthToDisplay = "";
      this.providerAdd = data;
    },

    onServiceSelected(data) {
      this.timeSlotLengthToDisplay = this.getTimeLengthDifference(
        data.milliseconds
      );
      this.serviceAdd = data;
    },

    async getDayEvent() {

      if (
        this.selectedEvent.provider[0].provider.providerId &&
        this.selectedEvent.location.location.locationId
      ) {
        let eventsBackup = this.events;
        this.events = [];

        eventsBackup.forEach((doc) => {
          if (doc.isSelected == true) {
            doc.location = this.eventLocation;
            doc.temp = true
            this.events.push(doc);
          }
        });




        try {
          const response = await this.$axios({
            method: "post",
            url: this.$axios.defaults.baseURL + "/api/getEventsForDayCalendarAdvanced",
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
            data: {
              providerIdList: this.doctor_list_dropdown,
              locationId: this.locationId,
              date: this.today,
            },
          });

          let events = []; // Declare events array
          let associatedEvents = [];

          response.data.forEach((doc) => {
            if (doc.offTime == true) {
              doc.start = (this.$moment(doc.start).tz('UTC').format('YYYY-MM-DD HH:mm:ss'))
              doc.end = (this.$moment(doc.end).tz('UTC').format('YYYY-MM-DD HH:mm:ss'))

            }
            doc.fromDatabase = true;
            if (doc.eventId === this.selectedEvent.eventId) {
              doc.color = "#6f00ff";
              doc.isSelected = true;
              if (doc.associatedEvents && doc.associatedEvents.length) {
                associatedEvents = doc.associatedEvents.filter(
                  (eventId) => eventId !== this.selectedEvent.eventId
                );
              }
            } else {
              doc.color = "#808080";
            }
            events.push(doc);
          });

          // If there are associated events, update their colors
          if (associatedEvents.length) {
            events.forEach((event) => {
              if (associatedEvents.includes(event.eventId)) {
                event.color = "#9a4dff";
                if (event.category && !this.categoryName.includes(event.category)) {
                  this.categoryName.push(event.category);
                }
              }
            });
          }

          this.events = events; // Set the events to the component state
        } catch (error) {
          console.error(error);
        }
      }
    },






    async getDayEventByDateSelection(date) {

      if (
        this.selectedEvent.provider[0].provider.providerId &&
        this.selectedEvent.location.location.locationId
      ) {
        let eventsBackup = this.events;
        this.events = [];
        eventsBackup.forEach((doc) => {
          if (doc.isSelected == true) {
            doc.color = "#6f00ff";
            let tempDifference = doc.end - doc.start;
            const sourceDate = new Date(doc.start);
            const targetDate = new Date(
              new Date(new Date(date).getTime() + this.$tzOffset)
            );
            const day = targetDate.getDate();
            const month = targetDate.getMonth();
            const year = targetDate.getFullYear();
            const hours = sourceDate.getHours();
            const minutes = sourceDate.getMinutes();
            const seconds = sourceDate.getSeconds();
            const milliseconds = sourceDate.getMilliseconds();
            const combinedDate = new Date(
              year,
              month,
              day,
              hours,
              minutes,
              seconds,
              milliseconds
            );
            const combinedTimestamp = combinedDate.getTime();
            doc.start = combinedTimestamp;
            doc.end = combinedTimestamp + tempDifference;
            this.changedEvent = doc;
            this.events.push(doc);
          }
        });

        let events = []
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/getEventsForDayCalendarAdvanced",
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          data: {
            providerIdList: this.doctor_list_dropdown,
            locationId: this.locationId,
            date: date,
          },
        }).then((response) => {
          response.data.forEach((doc) => {
            if (doc.offTime == true) {
              doc.start = (this.$moment(doc.start).tz('UTC').format('YYYY-MM-DD HH:mm:ss'))
              doc.end = (this.$moment(doc.end).tz('UTC').format('YYYY-MM-DD HH:mm:ss'))

            }
            doc.fromDatabase = true;
            if (doc.eventId == this.selectedEvent.eventId) {
              doc.color = "#6f00ff";
              doc.isSelected = true;
            } else {
              doc.color = "#808080";
            }
            events.push(doc)
          });
          this.events = events;
        }).catch((error) => console.log(error));


      }
    },

    async addServedList() {
      if (!this.providerAdd || !this.serviceAdd) {
        alert("Please select provider and service");
        return;
      }

      const selectedEventDate = new Date(this.selectedEvent.start);

      const morningStartTime = new Date(selectedEventDate);
      morningStartTime.setHours(0, 0, 0, 0);



      const dayEndTime = new Date(selectedEventDate);
      dayEndTime.setHours(23, 59, 59, 999);

      let potentialStartTime = morningStartTime.getTime();

      // Check if there is a selected event and log it
      const selectedEventInList = this.events.find((event) => event.isSelected);
      if (selectedEventInList) {
        potentialStartTime = selectedEventInList.end;
      }
      // Find the latest temporary event and adjust potential start time accordingly
      const tempLatestEvent = this.events
        .filter((event) => event.temp)
        .sort((a, b) => b.end - a.end)[0];
      if (tempLatestEvent) {
        potentialStartTime = Math.max(potentialStartTime, tempLatestEvent.end);
      }

      // Filter events by category and log them
      const filteredCategoryEvents = this.events
        .filter((event) => event.category === this.providerAdd.providerName && (!event.offTime || event.offTime === false))
        .sort((a, b) => a.start - b.start);


      let foundSlot = false;

      for (let i = 0; i <= filteredCategoryEvents.length; i++) {
        const nextEventStart =
          (filteredCategoryEvents[i] && filteredCategoryEvents[i].start) ||
          dayEndTime.getTime();

        // Log potential start time and the start time of the next event  

        if (
          potentialStartTime < nextEventStart &&
          nextEventStart - potentialStartTime >= this.serviceAdd.milliseconds
        ) {
          foundSlot = true;
          break;
        } else {
          // Log when slot is not found and the reason
          if (i < filteredCategoryEvents.length) {
            potentialStartTime = Math.max(
              potentialStartTime,
              filteredCategoryEvents[i].end
            );
          }
        }
      }

      if (!foundSlot) {
        alert("Unable to find a suitable slot for the event.");
        return;
      }


      const eventEndTime =
        potentialStartTime + parseInt(this.serviceAdd.milliseconds, 10);

      const newEvent = {
        UserId: this.selectedEvent.UserId,
        temp: true,
        id: Date.now(),
        fromDatabase: false,
        timed: true,
        checkinstatus: false,
        calEventStart: potentialStartTime,
        calEventEnd: eventEndTime,
        start: potentialStartTime,
        end: eventEndTime,
        category: this.providerAdd.providerName,
        first_name: this.selectedEvent.first_name,
        preferred_name: this.selectedEvent.preferred_name,
        last_name: this.selectedEvent.last_name,
        name: this.serviceAdd.serviceName,
        color: "#00d9ff",
        perVisitDetails: this.perVisitDetails,
        doctor: this.providerAdd,
        service: this.serviceAdd,
        location: this.selectedEvent.location.location,
        disabled: false,
      };

      this.serviceAdd.difference = this.getTimeLengthDifference(
        eventEndTime - potentialStartTime
      );

      const newService = {
        ...this.serviceAdd,
        id: Date.now(),
        perVisitDetails: this.perVisitDetails,
      };

      const newPair = {
        provider: this.providerAdd,
        service: newService,
        eventId: newEvent.id,
      };
      this.providerServicePairs.push(newPair);

      if (!this.categoryName.includes(this.providerAdd.providerName)) {
        this.categoryName.push(this.providerAdd.providerName);
      }

      this.events.push(newEvent);
      this.perVisitDetails = null;
    },
    deleteTimeSlot(item) {
      let pairIndex = this.providerServicePairs.findIndex(
        (pair) => pair.service.id === item.service.id
      );

      if (pairIndex !== -1) {
        this.providerServicePairs.splice(pairIndex, 1);
      }
      let eventIndex = this.events.findIndex(
        (event) => event.id === item.eventId
      );

      if (eventIndex !== -1) {
        this.events.splice(eventIndex, 1);
      }
    },

    startDrag({ event, timed }) {
      if (event.temp && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    async startTimeDayCategory(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      }
    },

    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      if (this.createEvent) {
        this.tempStart = this.createEvent.start;
        this.tempEnd = this.createEvent.end;
      }
      if (this.dragEvent) {
        this.tempStart = this.dragEvent.start;
        this.tempEnd = this.dragEvent.end;
      }

      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15;
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },

    async mouseMoveTimeCategory(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        this.createEvent.start = min;
        this.createEvent.end = max;

        let startNew = new Date(this.createEvent.start - this.$tzOffset);
        this.createEventStart =
          startNew.toISOString().slice(0, -1).split(":")[0] +
          ":" +
          startNew.toISOString().slice(0, -1).split(":")[1];
        this.createEvent.start = this.createEventStart;

        let endNew = new Date(this.createEvent.end - this.$tzOffset);

        this.createEventEnd =
          endNew.toISOString().slice(0, -1).split(":")[0] +
          ":" +
          endNew.toISOString().slice(0, -1).split(":")[1];
        this.createEvent.end = this.createEventEnd;
      }
    },

    async saveEvent() {
      let filteredEvents = this.events.filter(
        (event) => !event.fromDatabase && event.offTime !== true
      );
      let selectedEvent = await this.events.find((event) => event.isSelected);
      filteredEvents.forEach((event) => {
        event.name = this.selectedEvent.name;

      });
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/createAssociatedEvents",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          events: filteredEvents,
          selectedEventId: selectedEvent.eventId,
        },
      })
        .then(() => {
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },



    async changeLocation() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/changeLocation",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          eventId: this.selectedEvent.eventId,
          location: this.eventLocation,
        },
      })
        .then(() => {
          this.eventLocationChangedDialog = true;
        })
        .catch((error) => console.log(error));
    },
    async rescheduleEvent() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/rescheduleTime",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          eventId: this.changedEvent.eventId,
          userId: this.changedEvent.UserId,
          afterStart: this.changedEvent.start,
          afterEnd: this.changedEvent.end,

          beforeStart: this.selectedEvent.start,
          beforeEnd: this.selectedEvent.end,

          status: "reschedule",
          first_name: this.changedEvent.first_name,
        },
      })
        .then(() => {
          const storedData = localStorage.getItem("dwp");
          if (storedData) {
            let receivedDataDWP = JSON.parse(storedData);
            receivedDataDWP.selectedDate = this.today;
            localStorage.setItem("dwp", JSON.stringify(receivedDataDWP));
            this.$root.$emit("focusToday");
          }
          location.reload();
        })
        .catch((error) => console.log(error));
    },
    async missedEvent() {
      this.loading = true;
      try {
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/missedTime",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            status: "missed",
            first_name: this.selectedEvent.first_name,
            event: this.selectedEvent,
            userId: this.selectedEvent.UserId,
            start: new Date(this.selectedEvent.start).getTime(),
            end: new Date(this.selectedEvent.end).getTime(),
          },
        }).then((response) => {
          if (response.status === 200) {
            this.snackbar = true;
          } else {
            console.error(`Error: ${response.status}`);
          }
        });
      } catch (error) {
        console.error(`Error: ${error}`);
      } finally {
        this.loading = false;
        location.reload();
      }
    },
    async checkInEvent() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/checkInEvent",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          event: this.selectedEvent,
          location: this.eventLocation,
        },
      })
        .then(() => { })
        .catch((error) => console.log(error));
      this.service = "";
      this.doctor = "";
      this.doctorSelecter = "";
      this.start = "";
      this.end = "";
      location.reload();
    },

  },
};
</script>

<style scoped lang="scss">
.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.onelinebreak {
  display: flex;
  justify-content: space-between;
}
</style>
