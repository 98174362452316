<template>
  <div>
    <div>
      <v-card>
        <v-card-text>
          <v-card>
            <v-container>
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="today"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="today" label="Picker in menu" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="today" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(today)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-container>
          </v-card>

          <v-list dense>
            <v-list-item v-for="(service, index) in receiptServices" :key="index" class="mb-2">
              <v-card class="pa-3 full-width" outlined>
                <div>Service: {{ service.service.serviceName }}</div>
                <div>Provider: {{ service.provider.providerName }}</div>
                <div>Tax: {{ service.service.serviceHST ? 'Yes' : 'No' }}</div>
                <br />
                <span>Original Price: {{ service.service.servicePrice }}</span>
                <div class="d-flex align-center">
                  <span>Final Price: </span>
                  <v-text-field v-model="service.service.discountPrice" @input="updateDiscountPrice(index, $event)"
                    type="number" label="Unit Price" solo dense class="ml-2"></v-text-field>
                </div>
                <div class="d-flex align-center"><span>Amount: </span>
                  <v-text-field v-model="service.amount" @input="updateAmount(index, $event)" type="text" label="Amount"
                    solo dense class="ml-2">
                  </v-text-field>
                </div>

                <v-btn icon small color="red" @click="removeReceiptService(index)" class="mt-2">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card>
            </v-list-item>
          </v-list>
          <v-card>
            <v-container>
              <v-col>
                <div v-for="(item, index) in displayingProfessionalNumber" :key="index">
                  <input type="checkbox" :value="item" v-model="selectedItems">
                  {{ item.title }}
                </div>
              </v-col>
              <v-row>
                <v-col>
                  <providerSelector @providerSelected="onProviderSelected" :locationId="locationId" :key="locationId" />
                </v-col>
                <v-col>
                  <serviceSelector @serviceSelected="onServiceSelected" :providerId="providerId" :locationId="locationId"
                    :key="providerId + '-' + locationId" />
                </v-col>
              </v-row>
              {{ timeSlotLengthToDisplay }}
              <v-btn @click="addReceiptService" text small>Add This Service<v-icon>mdi-plus</v-icon></v-btn>
            </v-container>
          </v-card>
          <br />
          <v-btn color="primary" @click="printReceipt">Print Invoice & Save to Database</v-btn>
        </v-card-text>
      </v-card>
    </div>



    <!-- Invoice pdf -->
    <VueHtml2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
      :paginate-elements-by-height="1400" filename="Invoice" :pdf-quality="2" :manual-pagination="false" pdf-format="a4"
      pdf-orientation="portrait" pdf-content-width="800px" @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)" ref="invoicePDF">

      <section slot="pdf-content" class="marginall">
        <!-- Title -->
        <h1 class="center">Invoice</h1>

        <!-- Centered Address -->
        <div class="center">
          <p v-if="location">{{ this.location.location }}</p>
        </div>


        <!-- Right Aligned Invoice Number -->
        <div class="right-aligned">
          <p>Invoice Number: {{ invoiceNumber }}</p>
      
        </div>
        <!-- Left Aligned Details -->
        <div class="left-aligned">
          <p>Issue Date: {{ today }}</p>
          <p>Bill To: {{ this.oneUser && this.oneUser.basicInfo ? this.oneUser.basicInfo.first_name + ' ' +
            this.oneUser.basicInfo.last_name : 'N/A' }} </p>
          <p>Address: {{ this.oneUser ? this.oneUser.defaultAddress : 'N/A' }}</p>
          <p>Phone: {{ this.oneUser ? this.oneUser.defaultPhoneNumber : 'N/A' }}</p>
          <p>Email: {{ this.oneUser ? this.oneUser.defaultEmail : 'N/A' }}</p>
        </div>

        <br />
        <br />
        <!-- Services Table -->
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Unit Price ($)</th>
              <th>Amount</th>
              <th>Price ($)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(service, index) in receiptServices" :key="index">
              <td>{{ service.service.serviceName }}</td>
              <td>{{ parseFloat(service.service.servicePrice).toFixed(2) }}</td>
              <td>{{ service.amount }}</td>
              <td>{{ (service.service.discountPrice * service.amount).toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <!-- Right Aligned Totals -->
        <div class="right-aligned">
          <p>SubTotal: {{ subTotal.toFixed(2) }}</p>
          <p>Tax: {{ hst.toFixed(2) }}</p>
          <p>Total: {{ total.toFixed(2) }}</p>
        </div>
        <br />

        <!-- Bottom Details -->
        <div class="left-aligned">
          <p>Professional Number: </p>
          <p>Provider: {{ this.selectedEvent.provider[0].provider.providerName }}</p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <p>Signature: ______________________</p>
        </div>

      </section>
    </VueHtml2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    VueHtml2pdf,
  },
  name: "printInvoicePage",
  props: ["selectedEvent", "oneUserOriginal", "receiptServicesOriginal"],
  data: () => ({
    displayingProfessionalNumber: null,
    selectedItems: [],
    today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    //new service
    oneUser: [],
    menu: false,
    services: [],
    receiptServices: [],
    invoiceNumber: 'null',
    editing: false,
    locationId: null,
    providerId: null,
    providersList: null,
    userEmailToSendReceipt: null,
    servicesList: null,
    location: null,

    receiptHeader: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "date",
      },
      {
        text: "Total Price ($)",
        value: "total",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    receiptListItems: [],
    timeSlotLengthToDisplay: " ",

  }),
  async mounted() {
    this.oneUser = this.oneUserOriginal
    this.receiptServices = this.receiptServicesOriginal
    const storedOption = localStorage.getItem("lOp");
    if (storedOption) {
      this.location = JSON.parse(storedOption);
      this.locationId = this.location.locationId
    }
    //this.getAllRelatedEvents()
    this.getUserInfo(this.selectedEvent.UserId)
    this.getReceiptList()
  },
  watch: {

  },
  computed: {
    subTotal() {
      return this.receiptServices.reduce((total, service) => {
        const price = service.service.discountPrice ? service.service.discountPrice : service.service.servicePrice;
        return total + price * service.amount;
      }, 0);
    },
    hst() {
      return this.receiptServices.reduce((total, service) => {
        if (service.service.serviceHST) {
          const price = service.service.discountPrice ? service.service.discountPrice : service.service.servicePrice;
          const taxRate = service.service.hstRate && !isNaN(parseFloat(service.service.hstRate)) ? 1 + parseFloat(service.service.hstRate) / 100 : 1;
          return total + price * service.amount * (taxRate - 1);
        }
        return total;
      }, 0);
    },
    total() {
      return this.subTotal + this.hst;
    },
    totalSaved() {
      const originalTotal = this.receiptServices.reduce((total, service) => {
        return total + service.service.servicePrice * service.amount;
      }, 0);
      return originalTotal - this.total;
    },
    difference() {
      const originalTotal = this.receiptServices.reduce((total, service) => {
        const price = service.service.servicePrice * service.amount;
        return total + (service.service.serviceHST ? price * 1.13 : price);
      }, 0);
      return originalTotal - this.total;
    }
  },

  methods: {
    updateDiscountPrice(index, newPrice) {
      // Update the discountPrice for the specified service in receiptServices
      if (!this.receiptServices[index].service.discountPrice) {
        this.$set(this.receiptServices[index].service, 'discountPrice', newPrice);
      } else {
        this.receiptServices[index].service.discountPrice = newPrice;
      }
    },

    NewPageFn() {
      this.editing = false
      this.receiptServices = []
      this.getAllRelatedEvents()
    },

    async printReceiptNoDB() {
      this.$refs.invoicePDF.generatePdf()
      this.getReceiptList()
    },

    async printReceiptAndEmail() {
      let date = new Date()
      let dataToSave = {
        selectedEvent: this.selectedEvent,
        receiptServices: this.receiptServices,
        date: date,
        total: this.total
      }
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/receiptEmail",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          dataToSave: dataToSave,
          email: this.oneUser.defaultEmail
        }
      })
        .then((response) => {
          if (response.status == 200) {
            this.$refs.invoicePDF.generatePdf()
            this.getReceiptList()
          }
        })
        .catch((error) => console.log(error));
    },

    async printInvoice() {
      //Open invoice page and print invoice
    },

    async printReceipt() {
      let date = new Date()
      let dataToSave = {
        selectedEvent: this.selectedEvent,
        receiptServices: this.receiptServices,
        date: date,
        total: this.total.toFixed(2)
      }
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/receiptList/invoiceAdd",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          dataToSave: dataToSave
        }
      })
        .then((response) => {
          if (response.status == 200) {
            this.$refs.invoicePDF.generatePdf()
            this.getReceiptList()
          }
        })
        .catch((error) => console.log(error));
      //close it
      this.$emit('close')
    },

    async getReceiptList() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/receiptList/get",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          UserId: this.selectedEvent.UserId
        }
      })
        .then((response) => {
          this.receiptListItems = response.data
          this.receiptListItems.forEach((doc) => {
            doc.date = doc.date.split("T")[0]
          })
        })
        .catch((error) => console.log(error));
    },

    addReceiptService() {
      if (this.servicesList) {
        const newService = {
          ...this.servicesList,
          discountPrice: this.servicesList.servicePrice,
        };

        this.receiptServices.push({
          service: newService,
          provider: this.providersList,
          amount: 1
        });
      }
    },

    updateAmount(index, newAmount) {
      if (this.receiptServices[index]) {
        this.receiptServices[index].amount = newAmount;
      }
    },

    removeReceiptService(index) {
      this.receiptServices.splice(index, 1);
    },

    onProviderSelected(data) {
      this.providerId = data.providerId;
      this.timeSlotLengthToDisplay = ""
      this.providersList = data
      this.displayingProfessionalNumber = data.professionalNumber
    },
    onServiceSelected(data) {
      this.timeSlotLengthToDisplay = this.getTimeLengthDifference(
        data.milliseconds
      );
      this.servicesList = data;
    },
    getTimeLengthDifference(time) {
      let temp = time / 60000;
      let hours = Math.floor(temp / 60);
      let minutes = temp % 60;
      if (hours === 0) {
        return `${minutes} mins`;
      } else if (minutes === 0) {
        return `${hours} hr${hours > 1 ? "s" : ""}`;
      } else {
        return `${hours} hr${hours > 1 ? "s" : ""} ${minutes} mins`;
      }
    },
    async getAllRelatedEvents() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getAllRelatedEvents",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { selectedEvent: this.selectedEvent },
      })
        .then((doc) => {
          let appDataList = doc.data;
          appDataList.forEach((doc) => {
            // Extracting the required details
            if (doc.provider && doc.provider.length > 0) {
              let providerData = doc.provider[0];
              let serviceData = providerData.service && providerData.service.length > 0 ? providerData.service[0] : null;
              if (serviceData) {
                const serviceInfo = {
                  service: serviceData,
                  provider: providerData.provider,
                };
                // Populate both services and receiptServices
                this.services.push(serviceInfo);
                this.receiptServices.push({
                  service: { ...serviceData, discountPrice: serviceData.servicePrice },
                  provider: providerData.provider,
                  amount: 1
                });
              }
            }
          })
        }).catch((error) => console.log(error));
    },
    addService() {
      if (this.providersList && this.servicesList) {
        this.services.push({
          service: this.servicesList,
          provider: this.providersList,
        });
      }
    },

    removeService(index) {
      this.services.splice(index, 1);
    },

    async getUserInfo(event) {
      let user = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getOneUser",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { userId: event },
      })
        .then((response) => {
          let appData = response.data;
          this.userEmailToSendReceipt = appData.defaultEmail
          user = appData;
        })
        .catch((error) => console.log(error));

      this.oneUser = user;
      let addressTemp = this.oneUser.addresses.find(address => address.default === true);
      this.oneUser.defaultAddress = addressTemp.AddressLine1 + ' ' + addressTemp.AddressLine2 + ' ' + addressTemp.City + ' ' + addressTemp.Province + ' ' + addressTemp.PostalCode + ' ' + addressTemp.Country

    },

  },
};
</script>
<style scoped>
.highlight-text {
  font-size: larger;
  font-weight: bold;
  color: red;
}

.marginall {
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 100px;
  margin-left: 100px;
}

.full-width {
  width: 100%;
}

.center {
  text-align: center;
  justify-content: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  /* Center align text in table cells */
}

.line-through {
  text-decoration: line-through;
}

.highlight-discount {
  color: red;
  font-weight: bold;
  margin-left: 8px;
  /* Adjust as needed for spacing */
}

thead {
  background-color: #f0f0f0;
}

.left-aligned {
  text-align: left;
}

.right-aligned {
  text-align: right;
}
</style>
