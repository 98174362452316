<template>
  <div >
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="1000" class="unselectable">
          <!-- top bar for location and provider -->
          <v-row class="ml-3 scrollable-row">
            <v-col cols="12" sm="2" md="3">
              <v-select v-model="location" :item-text="'companyName'" :item-value="'companyName'" :items="locationList"
                label="Location" :day-format="dayFormat" dense outlined @change="getLocationId($event)" return-object>
              </v-select>
            </v-col>
            <v-col>
              <v-chip-group v-model="selectedProviderIndexes" multiple max="3" @change="selectProvider($event)"
                active-class="primary--text">
                <v-chip v-for="(key, index) in pickProvider" :key="key.providerName" :value="index">
                  {{ key.providerName }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <!-- calendar body itself -->
          <v-calendar ref="calendar" color="primary" v-model="focus" event-overlap-mode="stack" category-show-all
            interval-height="70" :now="today" :value="today" :type="'category'" short-intervals :events="events"
            :event-color="getEventColor" :event-ripple="true" :categories="filteredProviders"
            @click:event="leftClickEvent" :first-interval="6" :interval-minutes="60" :interval-count="18"
            @mousedown:event="startDrag" @mousemove:time="mouseMove" @mouseup:time="endDrag"
            @mousedown:time-category="startTimeDayCategory" @mousemove:time-category="mouseMoveTimeCategory"
            @mouseleave.native="cancelDrag">
            <template v-slot:event="{ event, timed, eventSummary }">
              <div  class="v-event-draggable">
                <component :is="{ render: eventSummary }"></component>
              </div>
              <div v-if="timed && !filterPastDate(event.start)" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)"></div>
            </template>
          </v-calendar>
          <!-- drag to create new appointment -->
          <v-dialog v-if="createNewEventMenu" v-model="createNewEventMenu" max-width="800px" persistent
            transition="scale-transition" :close-on-content-click="false" offset-x>
            <v-card>
              <v-card-title>New Appointment
                <v-spacer />
                <v-btn icon @click="createNewEventMenu = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <dayViewNewEventPage @cancelEvent="cancelEvent" :key="tempNewEventPageCounter"
                  :providerFill="providerFullInfo" :locationFill="selectedLocation" :startFill="createEventStart"
                  :endFill="createEventEnd" :dateOnly="dateOnly"></dayViewNewEventPage>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- event information page for checked in and not checked in -->
          <v-dialog scrollable v-if="selectedOpen" v-model="selectedOpen" width="unset" :activator="selectedElement"
            offset-x @input="(v) => v || refreshPage()" persistent>
            <v-card>
              <v-card-title dark>{{ this.selectedEvent.first_name }}
                {{ this.selectedEvent.last_name }}
                <div class="inlineblocking" v-if="selectedEvent.preferred_name">
                  ({{ this.selectedEvent.preferred_name }})
                </div>
                <v-spacer />

                <v-btn icon @click="selectedOpen = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <appointmentDetailPage :checkInStatus="checkInStatus" :selectedEvent="selectedEvent"
                  @reloadLocation="handleReloadLocation" :key="updateCETrue" />
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- yes triggers reschedule time, no just change time but does record in database -->
          <v-dialog v-model="confirmMoveTime" max-width="400px">
            <v-card>
              <v-card-title class="headline">Confirmation</v-card-title>
              <v-card-text>Is this a reschedule?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="rescheduleEvent">Yes</v-btn>
                <v-btn color="green darken-1" text @click="moveTime">No</v-btn>
                <v-btn color="green darken-1" text @click="cancelMoveTime">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="confirmExtendTime" max-width="400px">
            <v-card>
              <v-card-title class="headline">Confirmation</v-card-title>
              <v-card-text>Do you want to extend the time?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="extendTime">Yes</v-btn>
                <v-btn color="green darken-1" text @click="cancelExtendTime">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-snackbar v-model="snackbar" :timeout="3000" color="success">
            The time has been updated!
            <v-btn color="white" text @click="snackbar = false">Close</v-btn>
          </v-snackbar>
          <v-snackbar v-model="loading" :timeout="-1" color="info">
            Loading...
          </v-snackbar>
          <v-snackbar v-model="showPastDateAlert" :timeout="3000" color="red">
            Cannot Book Past Dates
            <v-btn color="white" text @click="showPastDateAlert = false">Close</v-btn>
          </v-snackbar>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue";
import appointmentDetailPage from "../components/calendarComponents/dailyAppointmentDetailPage.vue";
import dayViewNewEventPage from "../components/customPages/ToolBarPages/dayViewNewEventPage.vue";
import newEventPage from "../components/customPages/ToolBarPages/newEventPage.vue";
export default {
  name: "userCalendarDayView",
  components: {
    appointmentDetailPage,
    newEventPage,
    dayViewNewEventPage,
  },
  beforeDestroy() {
    this.$root.$off("nextDay", this.next);
    this.$root.$off("prevDay", this.prev);
    this.$root.$off("focusToday", this.focusTodayOnClick);
  },
  async created() {
    this.focusToday();
    this.$root.$on("nextDay", this.next);
    this.$root.$on("prevDay", this.prev);
    this.$root.$on("focusToday", this.focusTodayOnClick);
  },
  async mounted() {

    const locationData = localStorage.getItem("lOp");
    if (locationData) {
      this.selectedLocation = JSON.parse(locationData);
    }
    //get the provider and locations list
    await this.getProviderByLocation();

    await this.getFilteredProvider();
    Vue.prototype.$getLocationsList = async () => {
      let locationListTemp = [];
      await this.$axios({
        method: "get",
        url: this.$axios.defaults.baseURL + "/api/getLocationsList",
        headers: { Authorization: localStorage.getItem("accessToken") },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            locationListTemp.push(appData);
          });
        })
        .catch((error) => console.log(error));
      Vue.prototype.$LocationsListObj = locationListTemp;
    };
    Vue.prototype.$getDoctorsList = async () => {
      let doctors = [];
      await this.$axios({
        method: "get",
        url: this.$axios.defaults.baseURL + "/api/getDoctorsList",
        headers: { Authorization: localStorage.getItem("accessToken") },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            doctors.push(appData);
          });
        })
        .catch((error) => console.log(error));
      Vue.prototype.$doctorsListObj = doctors;
    };

    try {
      const response = await this.updateLocationList();

      if (response && Array.isArray(response.data)) {
        this.locationList = [{ companyName: "None", value: null }].concat(
          response.data
        );
      }
      this.loadSelectedOption(); // if there is pre selected location, load that first
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    const storedIndexes = localStorage.getItem("SPI");
    //if there is pre selected provider, load that, else load the first three
    if (storedIndexes) {
      this.selectedProviderIndexes = JSON.parse(storedIndexes).index;
      this.selectProvider(this.selectedProviderIndexes);
    } else {
      this.selectProvider(this.selectedProviderIndexes);
    }
    this.changeChipNumber++

  },
  data: () => ({
    confirmDrag: false,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    updateCETrue: 0,
    focus: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    settingsPageToggle: false,
    doctorNameOnly: [],
    type: "category",
    category: "category",
    typeToLabel: {
      month: "Day",
      week: "Week",
      category: "Day",
    },
    color: "#1976D2",
    colors: ["#2196F3"],
    events: [],
    createEventStart: "",
    createEventEnd: "",
    provider: "",
    selectedElement: null,
    selectedOpen: false,
    selectedEvent: {},
    checkInStatus: false,
    pickProvider: [],
    selectedProviderList: [],
    filteredProviders: [],
    value: "",
    dragEvent: null,
    confirmExtendTime: false,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    temp: false,
    tempProviderFullInfo: [],
    providerFullInfo: [],
    createNewEventMenu: false,
    tempNewEventPageCounter: 0,
    dateOnly: "",
    selectedLocation: [],
    location: "",
    locationList: [],
    locationId: "",
    selectedProviderIndexes: [],
    confirmMoveTime: false,
    loading: false,
    snackbar: false,
    rescheduleBeforeDragStart: null,
    rescheduleBeforeDragEnd: null,
    rescheduleAfterDragStart: null,
    rescheduleAfterDragEnd: null,
    showPastDateAlert: false,
  }),
  watch: {
    selectedOpen(val) {
      val || this.updateCE();
    },
  },
  methods: {
    dayFormat(date) {
      //get correct date
      const day = date.date.slice(-2);
      const month = new Date(date.date).toLocaleString("default", {
        month: "short",
      });
      return `${month} ${day}`;
    },
    async moveTime() {
      //change event's time but not record as a reschedule in database
      this.loading = true;
      try {
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/moveTime",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            eventId: this.selectedEvent.eventId,
            start: this.rescheduleAfterDragStart,
            end: this.rescheduleAfterDragEnd,
          },
        }).then((response) => {
          if (response.status === 200) {
            this.snackbar = true;
            this.loading = false;
          } else {
            console.error(`Error: ${response.status}`);
          }
        });
      } catch (error) {
        console.error(`Error: ${error}`);
      } finally {
        location.reload();
      }
    },
    focusToday: function () {
      //focus today when first time using /day
      const storedData = localStorage.getItem("dwp");
      if (storedData) {
        this.receivedDataDWP = JSON.parse(storedData);
        this.today = this.receivedDataDWP.selectedDate;
        this.focus = this.receivedDataDWP.selectedDate;
      }
      const locationData = localStorage.getItem("lOp");
      if (locationData) {
        this.selectedLocation = JSON.parse(locationData);
      }
    },
    focusTodayOnClick: function () {
      // focus today when clicked on today
      const storedData = localStorage.getItem("dwp");
      if (storedData) {
        this.receivedDataDWP = JSON.parse(storedData);
        this.today = this.receivedDataDWP.selectedDate;
        this.focus = this.receivedDataDWP.selectedDate;
      }
      const locationData = localStorage.getItem("lOp");
      if (locationData) {
        this.selectedLocation = JSON.parse(locationData);
      }
      if (storedData && locationData) {
        this.getEvents();
      }
    },






    async getEvents() {

      let events = []
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getEventsForDayCalendarAdvanced",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        data: {
          locationId: this.location.locationId,
          providerIdList: this.pickProvider,
          date: this.focus,
        },
      }).then((response) => {
        response.data.forEach((doc) => {
          if (doc.offTime == true) {
            doc.start = (this.$moment(doc.start).tz('UTC').format('YYYY-MM-DD HH:mm:ss'))
            doc.end = (this.$moment(doc.end).tz('UTC').format('YYYY-MM-DD HH:mm:ss'))
          }
          events.push(doc)
        });

        this.events = events;

      }).catch((error) => console.log(error));

    },





    getEventColor(event) {
      return event.color;
    },
    //saving data and
    nextPrevGetData() {
      const storedData = localStorage.getItem("dwp");
      const dataToPass = {
        selectedDate: this.focus,
        selectedProviders: JSON.parse(storedData).selectedProviders,
      };
      localStorage.setItem("dwp", JSON.stringify(dataToPass));
    },
    next: function () {
      this.events = [];
      this.$refs.calendar.next();
      this.nextPrevGetData();
      this.selectProvider(this.selectedProviderIndexes);
      this.$root.$emit("focusChanged", this.focus);
    },
    prev: function () {
      this.events = [];
      this.$refs.calendar.prev();
      this.nextPrevGetData();
      this.selectProvider(this.selectedProviderIndexes);
      this.$root.$emit("focusChanged", this.focus);
    },
    updateCE() {
      if (this.selectedOpen == true) {
        this.updateCETrue++;
      }
    },
    async refreshPage() {
      this.selectedOpen = false;
      this.createNewEventMenu = false;
      this.currentlyEditing = false;
      await this.getEvents();
    },
    cancelCreateEvent() {
      this.createNewEventMenu = false;
      this.events = this.events.filter(function (el) {
        return el.temp != true;
      });
    },
    async leftClickEvent({ nativeEvent, event }) {
      // detect if the event detail is opened, if not open it
      if (event.offTime) {
        this.selectedEvent = event;
      }
      if (!event.temp) {
        this.updateCETrue++;
        const open = () => {
          this.selectedEvent = event;
          if (this.selectedEvent.checkinstatus == true) {
            this.checkInStatus = true;
          } else {
            this.checkInStatus = false;
          }
          this.selectedElement = nativeEvent.target;
          if (this.confirmDrag == false && this.confirmExtendTime == false && !this.selectedEvent.offTime) {
            requestAnimationFrame(() =>
              requestAnimationFrame(() => (this.selectedOpen = true))
            );
          }
        };
        if (this.selectedOpen) {
          this.selectedOpen = false;
        } else {
          open();
        }
        nativeEvent.stopPropagation();
      } else if (event.temp) {
        //if it is a temp event, do not open detail page
        const open = () => {
          this.selectedEvent = event;
          let startNew = this.$moment(event.start)
          let endNew = this.$moment(event.end)
          this.createEventStart = startNew.format('YYYY-MM-DDTHH:mm');
          this.createEventEnd = endNew.format('YYYY-MM-DDTHH:mm');
          this.dateOnly = this.createEventStart.split("T")[0];
          this.createEventStart = this.createEventStart.split("T")[1];
          this.createEventEnd = this.createEventEnd.split("T")[1];
          if (this.confirmDrag == false) {
            requestAnimationFrame(() =>
              requestAnimationFrame(() => (this.createNewEventMenu = true))
            );
          }
        };
        if (this.createNewEventMenu) {
          this.createNewEventMenu = false;
        }
        if (this.selectedOpen) {
          this.selectedOpen = false;
        } else {
          open();
        }
        nativeEvent.stopPropagation();
      } else {
        this.temp = false;
      }
    },
 




    startDrag({ event, timed }) {
      //when dragging, save the data of the selected event for later use
      if (event && timed && !event.offTime) {
        this.selectedEvent = event;
        this.dragEvent = event;
        this.beforeDragIndex = this.events.indexOf(this.dragEvent);
        this.beforeDragCategory = event.category;
        this.beforeDragStart = event.start;
        this.beforeDragEnd = event.end;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    async startTimeDayCategory(tms) {
      if (
        this.createNewEventMenu == false &&
        !this.selectedEvent.checkinstatus
      ) { 
        const mouse = this.toTime(tms);
        if (this.dragEvent && this.dragTime === null) { 
          const start = this.dragEvent.start;
          this.dragTime = mouse - start;
        } else { 
          if (this.isPastDate(tms.date)) {
        // check if the time is in past, if it is, alert user
        this.showPastDateAlert = true;
        return;
      }
      this.showPastDateAlert = false;
          this.events = this.events.filter(
            (item) => !Object.prototype.hasOwnProperty.call(item, "temp")
          );
          this.createStart = this.roundTime(mouse);
          this.provider = tms.category;
          this.providerFullInfo =
            this.tempProviderFullInfo[
            this.tempProviderFullInfo.findIndex(
              (provider) => provider.providerName === tms.category
            )
            ];
          this.createEvent = {
            temp: true,
            name: `Event #${this.events.length}`,
            color: this.rndElement(this.colors),
            start: this.createStart,
            end: this.createStart + 300000,
            timed: true,
            location: "",
            category: tms.category,
            new: true,
          };
          this.events.push(this.createEvent); // create a temp event if all passed
        }
      }
    },

    filterPastDate(date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to start of the day
    return new Date(date) < today;
  },

    isPastDate(timestamp) {
      //check if the time clicked is in the past
      const offset = new Date().getTimezoneOffset() * 60000;
      const nowLocal = new Date(Date.now() - offset);
      const oneDayAgo = new Date(nowLocal - 24 * 60 * 60 * 1000).setHours(
        0,
        0,
        0,
        0
      );
      const selectedDate = new Date(timestamp).setHours(0, 0, 0, 0);
      return selectedDate < oneDayAgo;
    },
    extendBottom(event) {
      //extending bottom of event
      if (!event.offTime) {
        this.beforeDragIndex = this.events.indexOf(event);
        this.beforeDragCategory = event.category;
        this.beforeDragStart = event.start;
        this.beforeDragEnd = event.end;
        this.createEvent = event;
        this.createStart = event.start;
        this.extendOriginal = event.end;
      }
    },
    async mouseMove(tms) {
      // finding location of mouse and determine the length of event
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime) {
        if (tms.category) {
          this.dragEvent.category = tms.category;
        }
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    async mouseMoveTimeCategory(tms) {
      // find in time category how does the mouse move
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime) {
        if (tms.category) {
          this.dragEvent.category = tms.category;
        }
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        this.createEvent.start = min;
        this.createEvent.end = max;
        let startNew = new Date(this.createEvent.start - this.$tzOffset);
        this.createEventStart =
          startNew.toISOString().slice(0, -1).split(":")[0] +
          ":" +
          startNew.toISOString().slice(0, -1).split(":")[1];
        this.createEvent.start = this.createEventStart;
        let endNew = new Date(this.createEvent.end - this.$tzOffset);
        this.createEventEnd =
          endNew.toISOString().slice(0, -1).split(":")[0] +
          ":" +
          endNew.toISOString().slice(0, -1).split(":")[1];
        this.createEvent.end = this.createEventEnd;
      }
    },
    async endDrag() {
      // when ending the drag, reset everything
      if (
        this.dragEvent &&
        this.dragEvent.temp != true &&
        !this.dragEvent.checkinstatus
      ) {
        if (
          this.events[this.beforeDragIndex].end != this.beforeDragEnd ||
          this.events[this.beforeDragIndex].start != this.beforeDragStart ||
          this.events[this.beforeDragIndex].category != this.beforeDragCategory
        ) {
          this.selectedOpen = false;
          this.selectedElement = null;
          this.confirmDrag = true;
          this.confirmMoveTime = true;
        }
      }
      if (this.dragEvent) {
        this.rescheduleBeforeDragStart = this.beforeDragStart;
        this.rescheduleBeforeDragEnd = this.beforeDragEnd;
        this.rescheduleAfterDragStart = this.dragEvent.start;
        this.rescheduleAfterDragEnd = this.dragEvent.end;
      } else {
        if (this.extendOriginal != null) {
          this.confirmExtendTime = true;
        }
        this.temp = false;
        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;
      }
    },
    async extendTime() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/extendBottom",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        data: {
          endTime: this.selectedEvent.end,
          eventId: this.selectedEvent.eventId,
        },
      })
        .then(() => {
          location.reload();
        })
        .catch((error) => console.log(error));
    },
    async cancelExtendTime() {
      this.selectedEvent.end = this.beforeDragEnd;
      this.confirmExtendTime = false;
    },
    cancelDrag() {
      // when cancel, reset everything
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      // round time so it fits calendar
      const roundTo = 15;
      const roundDownTime = roundTo * 60 * 1000;
      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    async getLocationId(event) {
      // get location from either user input or local storage
      this.locationId = event;
      if (event) {
        localStorage.setItem("lOp", JSON.stringify(event));
      }
      const providers = await this.getProviderByLocation();
      const length = providers.length;
      let indexes;
      if (length >= 3) {
        indexes = [0, 1, 2];
      } else {
        indexes = Array.from({ length: length }, (_, i) => i);
      }
      this.selectedProviderIndexes = indexes;
      this.selectProvider(this.selectedProviderIndexes);
    },
    async selectProvider(event) {
      //when change provider form top bar, filter events based on new provider list and sync localstorage
      this.events = [];
      if (event) {
        event = event.sort();
        let tempList = [];
        event.forEach((doc) => {
          if (this.pickProvider[doc]) {
            tempList.push(this.pickProvider[doc]);
          }
        });
        this.selectedProvidersList = tempList;
      }
      this.changeChipNumber++;
      const storedOption = localStorage.getItem("lOp");
      if (storedOption) {
        this.location = JSON.parse(storedOption);
        this.selectedLocation = this.location;
      }

      this.changeChipNumber++;
      this.allowRender = false;
      this.allowRender = true;
      let SPIobj = { index: event, location: this.location.locationId };
      localStorage.setItem("SPI", JSON.stringify(SPIobj));
      let tempProviderNameList = [];
      let tempProviderFullInfo = [];
      this.receivedDataDWP.selectedProviders.forEach((doc) => {
        tempProviderNameList.push(doc.providerName);
        tempProviderFullInfo.push(doc);
      });
      this.filteredProviders = tempProviderNameList;
      this.tempProviderFullInfo = tempProviderFullInfo;
      this.updateCategories();
      this.getEvents();
    },
    async getProviderByLocation() {
      //get provider's list based on location and put to v-chip
      let uniqueId = "";
      const storedOption = localStorage.getItem("lOp");
      if (storedOption) {
        uniqueId = JSON.parse(storedOption).locationId;
      }
      let doctors = [];
      await this.$axios({
        method: "post",
        url:
          this.$axios.defaults.baseURL + "/api/getProviderFilterWithLocation",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: uniqueId,
        },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            doctors.push(appData);
          });
        })
        .catch((error) => console.log(error));
      this.pickProvider = doctors;
      return this.pickProvider;
    },
    async updateLocationList() {
      // update location's list
      await this.$getLocationsList();
      let data = this.$LocationsListObj;
      this.locationList = data;
      return new Promise((resolve) => {
        resolve({
          data,
        });
      });
    },
    loadSelectedOption() {
      //load location and provider data from local storage
      const storedOption = localStorage.getItem("lOp");
      if (storedOption) {
        this.location = JSON.parse(storedOption);
      }
    },
    async getFilteredProvider() {
      const storedData = localStorage.getItem("dwp");
      if (storedData) {
        this.receivedDataDWP = JSON.parse(storedData);
      }
      let tempProviderNameList = [];
      let tempProviderFullInfo = [];
      await this.receivedDataDWP.selectedProviders.forEach((doc) => {
        tempProviderNameList.push(doc.providerName);
        tempProviderFullInfo.push(doc);
      });
      this.filteredProviders = tempProviderNameList;
      this.tempProviderFullInfo = tempProviderFullInfo;
    },
    async updateCategories() {
      //update name of category
      const dataToPass = {
        selectedDate: this.focus,
        selectedProviders: this.selectedProvidersList,
      };
      localStorage.setItem("dwp", JSON.stringify(dataToPass));
      this.getFilteredProvider();
    },
    async rescheduleEvent() {
      //if reschedule, store into database as a reschedule and affect loyalty
      this.loading = true;
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/rescheduleTime",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          eventId: this.selectedEvent.eventId,
          userId: this.selectedEvent.UserId,
          beforeStart: this.selectedEvent.start,
          beforeEnd: this.selectedEvent.end,
          afterStart: new Date(this.selectedEvent.start).getTime(),
          afterEnd: new Date(this.selectedEvent.end).getTime(),
          status: "reschedule",
          first_name: this.selectedEvent.first_name,
        },
      })
        .then(() => {
          location.reload();
        })
        .catch((error) => console.log(error));
    },
    cancelMoveTime() {
      this.confirmDrag = false;
      this.events[this.beforeDragIndex].start = this.beforeDragStart;
      this.events[this.beforeDragIndex].end = this.beforeDragEnd;
      this.confirmMoveTime = false;
    },
    cancelEvent() {
      location.reload();
    },
    handleReloadLocation(event) {
      this.getLocationId(event);
      location.reload();
    },
  },
};
</script>
<style scoped lang="scss">
.scrollable-row {
  max-height: 200px;
  overflow-y: auto;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.container-outline {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
  margin-left: 30px;
}

.onelinebreak {
  display: flex;
  justify-content: space-between;
}

.marginallaround {
  margin-left: 10px;
  margin-right: 10px;
}

.marginaround {
  margin: 10px;
}

.alignright {
  float: right;
}

.borderedLine {
  border-style: solid;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border: 2px solid;
  border-radius: 5px;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
