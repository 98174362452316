import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import routes from "./router";
import axios from "axios";
import "./interceptors/axios";
import moment from 'moment';
import 'moment-timezone';
Vue.prototype.$moment = moment;

import store from "./store";
Vue.prototype.$tzOffset = new Date().getTimezoneOffset() * 60000;
import newPatient from "./components/calendarComponents/newPatient.vue";
Vue.component("newPatient", newPatient);
import patientInformationDisplay from "./components/calendarComponents/patientInformationDisplay.vue";
Vue.component("patientInformationDisplay", patientInformationDisplay);
import patientDetailPage from "./components/customPages/ToolBarPages/patientDetailPage.vue";
Vue.component("patientDetailPage", patientDetailPage);
import patientDetailSetting from "./components/customPages/ToolBarPages/patientDetailSetting.vue";
Vue.component("patientDetailSetting", patientDetailSetting);

import invoicePage from "./components/invoiceAndReceipt/invoicePage.vue";
Vue.component("invoicePage", invoicePage);


import receiptPage from "./components/invoiceAndReceipt/receiptPage.vue";
Vue.component("receiptPage", receiptPage);

import allBalancePage from "./components/invoiceAndReceipt/allBalancePage.vue";
Vue.component("allBalancePage", allBalancePage);

import patientNotesHistory from "./components/patientDetail/patientNotesHistory.vue";
import patientPhoneHistory from "./components/patientDetail/patientPhoneHistory.vue";
import patientEmailHistory from "./components/patientDetail/patientEmailHistory.vue";
import patientAddressHistory from "./components/patientDetail/patientAddressHistory.vue";
import printInvoicePage from "./components/invoiceAndReceipt/printInvoicePage.vue";
import getRescheduleTime from "./components/smallComponents/getRescheduleTime.vue";
import locationSelector from "./components/smallComponents/locationSelector.vue";
import providerSelector from "./components/smallComponents/providerSelector.vue";
import invoicePDF from "./components/smallComponents/invoicePDF.vue";
import serviceSelector from "./components/smallComponents/serviceSelector.vue";
import TimeRangePicker from "vuetify-time-range-picker";
Vue.component("invoicePDF", invoicePDF);

Vue.component("PatientNotesHistory", patientNotesHistory);
Vue.component("printInvoicePage", printInvoicePage);
Vue.component("patientPhoneHistory", patientPhoneHistory);
Vue.component("patientEmailHistory", patientEmailHistory);
Vue.component("patientAddressHistory", patientAddressHistory);
Vue.component("getRescheduleTime", getRescheduleTime);
Vue.component("locationSelector", locationSelector);
Vue.component("providerSelector", providerSelector);
Vue.component("serviceSelector", serviceSelector);
Vue.component("TimeRangePicker", TimeRangePicker);
//axios.defaults.baseURL = 'https://octchainbackend.link'
//axios.defaults.baseURL = 'http://localhost:'
axios.defaults.baseURL = 'http://testoct.us-east-2.elasticbeanstalk.com'
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
new Vue({
  App,
  vuetify,
  router: routes,
  store,
  render: (h) => h(App),
}).$mount("#app");
