<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" sort-by="time" :sort-desc="true"  :items="displayedNotes" class="elevation-1">
          <template v-slot:item="{ item }">
            <tr @click="rowClicked(item)">
              <td :style="{ color: item.color ? item.color : 'defaultColor' }">{{ item.date }}</td>
              <td :style="{ color: item.color ? item.color : 'defaultColor' }">{{ item.perVisitDetails }}</td>
              <td :style="{ color: 'red' }">{{ item.balance }}</td>
              <td :style="{ color: item.color ? item.color : 'defaultColor' }">{{ item.provider[0].provider.providerName
              }}</td>
              <td :style="{ color: item.color ? item.color : 'defaultColor' }">{{ item.location.location.companyName }}
              </td>
              <td :style="{ color: item.color ? item.color : 'defaultColor' }">{{ item.status }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-btn block v-if="!allNotesVisible" @click="showMore">Show More</v-btn>
        <v-btn block v-if="allNotesVisible" @click="showLess">Show Less</v-btn>
        <br />
      </v-col>
    </v-row>



    <v-dialog scrollable v-if="selectedOpen" v-model="selectedOpen" width="800px" @input="(v) => v || refreshPage()"
      persistent>
      <v-card>
        <v-card-title dark>
          <div class="inlineblocking">Selected Visit
          </div>
          <v-spacer />

          <v-btn icon @click="selectedOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          Status: {{ selectedEvent.status }}
          Receipt: to be developed.....
        </v-card-text>
      </v-card>
    </v-dialog>









  </div>
</template>


<script>
export default {
  name: "NotesHistory",
  props: {
    userId: String,
  },
  data: () => ({
    selectedEvent: [],
    selectedOpen: false,
    headers: [
      { text: 'Date', value: 'time' },
      { text: 'Visit Notes', value: 'perVisitDetails' },
      { text: 'Balance', value: 'balance' },
      { text: 'Provider', value: 'provider' },
      { text: 'Location', value: 'location' },
      { text: 'Status', value: 'status' }
    ],
    notesHistory: [],
    allNotesVisible: false,
  }),
  computed: {
    displayedNotes() {
      if (this.allNotesVisible) {
        return this.notesHistory;
      } else {
        return this.notesHistory.slice(0, 3);
      }
    },
  },
  async mounted() {
    await this.getNotesHistory();
  },
  methods: {
    rowClicked(item) {
      this.selectedOpen = true
      this.selectedEvent = item
    },
    showMore() {
      this.allNotesVisible = true;
    },
    showLess() {
      this.allNotesVisible = false;
    },
    async getNotesHistory() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getNotesHistory",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { userId: this.userId },
      })
        .then((response) => {
          let notesHistory = response.data.map(note => {
            let dateTime = new Date(note.time); // Parse the timestamp
            if (note.balance == 0 || note.balance == '0'){
              note.balance= null
            }
            // Format the combined date and time
            let formattedDateTime = dateTime.toLocaleDateString("en-US") + ' ' + dateTime.toLocaleTimeString("en-US");

            // Replace the original timestamp with the formatted date-time string
            return {
              ...note,
              date: formattedDateTime
            };
          });

          // Sort the notes from latest to earliest
          notesHistory.sort((a, b) => b.time - a.time);

          this.notesHistory = notesHistory
        })
        .catch((error) => console.log(error));
    }

  },
};
</script>
