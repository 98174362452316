<template>
  <v-card flat>
    <v-card-text>
      <v-btn
        color="primary"
        @click="(addNewService = true), (addServiceName = null)"
      >
        New Service
      </v-btn>
      <v-data-table
        :headers="serviceListHeader"
        :items="serviceListItem"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editServiceItem(item)"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
      <v-dialog v-model="addNewService" max-width="450">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Add New Service</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="addNewService = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-container>
            <v-form @submit.prevent="addServiceEvent" class="container-outline">
              <v-text-field
                v-model="addServiceName"
                type="text"
                label="Service Name"
              ></v-text-field>

              <v-btn
                type="submit"
                color="primary"
                class="mb-4"
                @click.stop="addNewService = false"
                :disabled="!addServiceName"
                >Add</v-btn
              >
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editServicePage" max-width="450">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Edit Service</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="editServicePage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-container>
            Do you want to delete this Service? (This Action Cannot Be Undone)
            <v-row>
              <v-col class="d-flex" cols="12" sm="2">
                <div class="items-end">
                  <v-icon
                    @click="
                      deleteService(selectedService), (editServicePage = false)
                    "
                    >Yes mdi-delete
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<style scoped>
table,
td,
th {
  border: 1px solid;
}
.center {
  text-align: center;
  position: relative;
}
</style>
<script>
export default {
  name: "settingsPage",
  created() {},
  props: ["userGroup"],
  components: {},
  data: () => ({
    serviceCounter: 0,
    addServiceHST: false,
    addNewService: false,
    addServiceName: null,
    addServiceUnit: null,
    addServicePrice: null,

    editedItem: {},
    serviceListHeader: [
      { text: "Service Name", value: "serviceName" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    serviceListItem: [],

    serviceList: [],
    serviceType: null,
    Unit: null,
    Price: null,
    selectedService: [],
    editServicePage: false,
  }),
  async mounted() {
    await this.getServiceList();
  },
  methods: {
    async editServiceItem(item) {
      this.selectedService = Object.assign({}, item);
      this.addServiceName = this.selectedService.serviceName;
      this.addServicePrice = this.selectedService.servicePrice;
      this.addServiceHST = this.selectedService.serviceHST;
      this.editServicePage = true;
    },

    async getServiceList() {
      let services = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/asl/get",
        headers: { Authorization: localStorage.getItem("accessToken") },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            services.push(appData);
          });
        })
        .catch((error) => console.log(error));
      this.serviceListItem = services;
    },

    async addServiceEvent() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/asl/add",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          serviceName: this.addServiceName,
          serviceHST: this.addServiceHST,
          servicePrice: this.addServicePrice,
        },
      }).catch((error) => console.log(error));
      this.getServiceList();
      this.addServiceName = null;
      this.addServiceHST = false;
      this.addServicePrice = null;
    },
    async deleteService(item) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/asl/delete",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { docId: item.serviceId },
      }).catch((error) => console.log(error));
      this.addServiceName = null;
      this.addServiceHST = false;
      this.addServicePrice = null;
      await this.getServiceList();
    },
  },
};
</script>
