import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    calendarDate: new Date(),
  },
  mutations: {
    prevDay(state) {
      let date = new Date(state.calendarDate);
      date.setDate(date.getDate() - 1);
      state.calendarDate = date;
    },
    nextDay(state) {
      let date = new Date(state.calendarDate);
      date.setDate(date.getDate() + 1);
      state.calendarDate = date;
    },
  },
});
