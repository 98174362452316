<template>
  <div>
    <v-btn color="primary" @click="openNewInvoice">New Invoice</v-btn>
    <!-- print invoice -->
    <v-dialog v-model="printInvoicePage" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">New Invoice</span>
          <v-spacer />
          <v-btn icon @click="printInvoicePage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card >
          <v-card-text>
            <v-card style="background-color: #f0fff3;" >
              <v-container>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="today"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="today" label="Date" prepend-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="today" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(today)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-container>
            </v-card>
          </v-card-text>

          <v-list dense>
            <v-list-item v-for="(service, index) in invoiceServices" :key="index" class="mb-2">
              <v-card class="pa-3 full-width" outlined>
                <div>Service: {{ service.service.serviceName }}</div>
                <div>Provider: {{ service.provider.providerName }}</div>
                <div>Tax: {{ service.service.serviceHST ? "Yes" : "No" }}</div>
                <br />
                <span>Original Price: {{ service.service.servicePrice }}</span>
                <div class="d-flex align-center">
                  <span>Final Price: </span>
                  <v-text-field v-model="service.service.servicePrice" type="number" label="Unit Price" solo dense
                    class="ml-2"></v-text-field>
                </div>
                <div class="d-flex align-center">
                  <span>Amount: </span>
                  <v-text-field v-model="service.service.amount"  type="number" label="Amount" solo dense
                    class="ml-2">
                  </v-text-field>
                </div>
                <v-btn icon small color="red" @click="removeService(index)" class="mt-2">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card>
            </v-list-item>
          </v-list>
          <v-card-text>
            <v-card style="background-color: #f0fff3;">
              <v-container>
               
                <v-col>
                <v-row>
                  <v-col>
                    <providerSelector @providerSelected="onProviderSelected" :locationId="locationId" :key="locationId" />
                  </v-col>
                  <v-col>
                    <serviceSelector @serviceSelected="onServiceSelected" :providerId="providerId"
                      :locationId="locationId" :key="providerId + '-' + locationId" />
                  </v-col>
                </v-row>
                {{ timeSlotLengthToDisplay }}
     
                <v-col>
                  <v-radio-group v-model="selectedItems" @change="changeNumber">
                    <v-radio v-for="(item, index) in displayingProfessionalNumber" :key="index" :label="item.title"
                      :value="item"></v-radio>
                  </v-radio-group>
                  Professional Number: {{ selectedNumber }}
                </v-col>
                <v-btn @click="addService" text small :disabled="!selectedNumber">
                  Add This Service
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              </v-container>
            </v-card>
            <br />
            <v-btn color="primary" @click="saveInvoice" :disabled="invoiceServices.length === 0">
              Save Invoice to Database
            </v-btn>

          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>

    <!-- invoice history -->
    <br />
    <b>Invoice History</b>
    <v-data-table :sort-by="defaultSortBy" :sort-desc="defaultSortDesc" :headers="invoiceHeader" :items="invoiceListItems"
      :items-per-page="5" class="elevation-1">
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editInvoiceItem(item)">mdi-pencil</v-icon>
      </template>
    </v-data-table>

    <!-- Edit Invoice Section -->
    <v-dialog v-model="editInvoicePage" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Current Invoice</span>
          <v-spacer />
          <v-btn icon @click="editInvoicePage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="(service, index) in invoiceServices" :key="index" class="mb-2">
              <v-card class="pa-3 full-width" outlined>
                <div>Service: {{ service.service.serviceName }}</div>
                <div>Provider: {{ service.provider.providerName }}</div>
                <div>Tax: {{ service.service.serviceHST ? "Yes" : "No" }}</div>
                <br />
                <span>Price/Unit: {{ service.service.servicePrice }}</span>
                <div class="d-flex align-center">
                  <span>Amount: {{ service.service.amount }}</span>
                </div>
              </v-card>
            </v-list-item>
          </v-list>
          <v-card>
            <v-container> Total: {{ this.total.toFixed(2) }} </v-container>
          </v-card>
          <br />
          <v-btn color="primary" @click="printInvoiceNoDB">Print Current Invoice</v-btn>
          <br />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Invoice pdf -->
    <VueHtml2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
      :paginate-elements-by-height="1400" filename="Invoice" :pdf-quality="2" :manual-pagination="false" pdf-format="a4"
      pdf-orientation="portrait" pdf-content-width="800px" @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)" ref="invoicePDF">
      <section slot="pdf-content" class="marginall">
        <!-- Title -->
        <h1 class="center">Invoice</h1>

        <!-- Centered Address -->
        <div class="center">
          <p v-if="location">{{ this.location.location }}</p>
        </div>

        <!-- Right Aligned Invoice Number -->
        <div class="right-aligned">
          <p>Invoice Number: {{ invoiceNumber }}</p>
        </div>
        <!-- Left Aligned Details -->
        <div class="left-aligned">
          <p>Issue Date: {{ today }}</p>
          <p>
            Bill To:
            {{
              this.oneUser && this.oneUser.basicInfo
              ? this.oneUser.basicInfo.first_name +
              " " +
              this.oneUser.basicInfo.last_name
              : "N/A"
            }}
          </p>
          <p>
            Address: {{ this.oneUser ? this.oneUser.defaultAddress : "N/A" }}
          </p>
          <p>
            Phone: {{ this.oneUser ? this.oneUser.defaultPhoneNumber : "N/A" }}
          </p>
          <p>Email: {{ this.oneUser ? this.oneUser.defaultEmail : "N/A" }}</p>
        </div>

        <br />
        <br />
        <!-- Services Table -->
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Unit Price ($)</th>
              <th>Amount</th>
              <th>Price ($)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(service, index) in invoiceServices" :key="index">
              <td>{{ service.service.serviceName }}</td>
              <td>{{ parseFloat(service.service.servicePrice).toFixed(2) }}</td>
              <td>{{ service.service.amount }}</td>
              <td>
                {{
                  (
                    service.service.servicePrice * service.service.amount
                  ).toFixed(2)
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <!-- Right Aligned Totals -->
        <div class="right-aligned">
          <p>SubTotal: {{ subTotal.toFixed(2) }}</p>
          <p>Tax: {{ hst.toFixed(2) }}</p>
          <p>Total: {{ total.toFixed(2) }}</p>
        </div>
        <br />

        <!-- Bottom Details -->
        <div class="left-aligned">
          <p>Professional Number: {{ selectedNumber }}</p>
          <p>
            Provider: {{ this.selectedEvent.provider[0].provider.providerName }}
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <p>Signature: ______________________</p>
        </div>
      </section>
    </VueHtml2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    VueHtml2pdf,
  },
  name: "invoicePage",
  props: ["selectedEvent"],
  data: () => ({
    selectedNumber: null,
    displayingProfessionalNumber: null,
    selectedItems: [],
    menu: false,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    invoiceNumber: "null",
    printInvoicePage: false,
    invoiceCounter: 0,
    oneUser: null,
    paymentMethods: ["Cash", "Credit Card", "Cheque", "EMT", "Gift Card"],
    selectedPaymentMethod: "",
    //new service
    services: [],
    editInvoicePage: false,

    editing: false,
    locationId: null,
    providerId: null,
    providersList: null,
    servicesList: null,
    invoiceServices: [],
    location: null,
    defaultSortBy: "date",
    defaultSortDesc: true,

    invoiceHeader: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "date",
      },
      {
        text: "Total Price ($)",
        value: "total",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],

    invoiceListItems: [],
    timeSlotLengthToDisplay: " ",
  }),
  async mounted() {
    const storedOption = localStorage.getItem("lOp");
    if (storedOption) {
      this.location = JSON.parse(storedOption);
      this.locationId = this.location.locationId;
    }
    this.getAllRelatedEvents();
    this.getUserInfo(this.selectedEvent.UserId);
    this.getInvoiceList();
  },
  watch: {},
  computed: {
    subTotal() {
      return this.invoiceServices.reduce((total, service) => {
        const price = parseFloat(service.service.servicePrice);
        const amount = parseFloat(service.service.amount);
        return total + price * amount;
      }, 0);
    },
    hst() {
      return this.invoiceServices.reduce((total, service) => {
        if (service.service.serviceHST) {
          const price = parseFloat(service.service.servicePrice);
          const amount = parseFloat(service.service.amount);
          const taxRate =
            service.service.hstRate &&
              !isNaN(parseFloat(service.service.hstRate))
              ? 1 + parseFloat(service.service.hstRate) / 100
              : 1;
          return total + price * amount * (taxRate - 1);
        }
        return total;
      }, 0);
    },
    total() {
      return this.subTotal + this.hst;
    },
    totalSaved() {
      const originalTotal = this.invoiceServices.reduce((total, service) => {
        const price = parseFloat(service.service.servicePrice);
        const amount = parseFloat(service.service.amount);
        return total + price * amount;
      }, 0);
      return originalTotal - this.total;
    },
    difference() {
      const originalTotal = this.invoiceServices.reduce((total, service) => {
        const price = parseFloat(service.service.servicePrice);
        const amount = parseFloat(service.service.amount);
        return (
          total +
          (service.service.serviceHST ? price * amount * 1.13 : price * amount)
        );
      }, 0);
      return originalTotal - this.total;
    },
  },

  methods: {

    async printInvoiceNoDB() {
      this.$refs.invoicePDF.generatePdf();
    },

    openNewInvoice() {
      this.invoiceServices = [];
      this.printInvoicePage = true;
    },
    async saveInvoice() {
      let subTotal = this.invoiceServices.reduce((total, service) => {
        const price = parseFloat(service.service.servicePrice);
        const amount = parseFloat(service.service.amount);
        return total + price * amount;
      }, 0);

      let hst = this.invoiceServices.reduce((total, service) => {
        if (service.service.serviceHST) {
          const price = parseFloat(service.service.servicePrice);
          const amount = parseFloat(service.service.amount);
          const taxRate =
            service.service.hstRate &&
              !isNaN(parseFloat(service.service.hstRate))
              ? 1 + parseFloat(service.service.hstRate) / 100
              : 1;
          return total + price * amount * (taxRate - 1);
        }
        return total;
      }, 0);
      let total = subTotal + hst;

      let date = new Date();
      let dataToSave = {
        selectedEvent: this.selectedEvent,
        invoiceServices: this.invoiceServices,
        date: date,
        total: total.toFixed(2),
        professionalNumber: this.selectedNumber,
      };
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/receiptList/invoiceAdd",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          dataToSave: dataToSave,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.getInvoiceList();
            this.printInvoicePage = false;
            this.invoiceServices = [];
          }
        })
        .catch((error) => console.log(error));
      //close it
    },
    editInvoiceItem(data) {
      this.editing = true;
      this.invoiceCounter++;
      this.invoiceNumber = data.invoiceNumber || "null";
      this.selectedPaymentMethod = data.paymentMethod;
      this.selectedNumber = data.professionalNumber;
      this.invoiceServices = data.invoiceServices;
      this.editInvoicePage = true;
    },

    async printInvoice() {
      this.printInvoicePage = true;
      //Open invoice page and print invoice
    },

    async getInvoiceList() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/receiptList/getInvoice",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          UserId: this.selectedEvent.UserId,
        },
      })
        .then((response) => {
          this.invoiceListItems = response.data;
          this.invoiceListItems.forEach((doc) => {
            doc.date =
              doc.date.split("T")[0] +
              " " +
              doc.date.split("T")[1].split(".")[0];
          });
        })
        .catch((error) => console.log(error));
    },

    onProviderSelected(data) {
      this.providerId = data.providerId;
      this.timeSlotLengthToDisplay = "";
      this.providersList = data;
      this.displayingProfessionalNumber = data.professionalNumber;
    },
    onServiceSelected(data) {
      this.timeSlotLengthToDisplay = this.getTimeLengthDifference(
        data.milliseconds
      );
      this.servicesList = data;
    },
    getTimeLengthDifference(time) {
      let temp = time / 60000;
      let hours = Math.floor(temp / 60);
      let minutes = temp % 60;
      if (hours === 0) {
        return `${minutes} mins`;
      } else if (minutes === 0) {
        return `${hours} hr${hours > 1 ? "s" : ""}`;
      } else {
        return `${hours} hr${hours > 1 ? "s" : ""} ${minutes} mins`;
      }
    },
    async getAllRelatedEvents() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getAllRelatedEvents",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { selectedEvent: this.selectedEvent },
      })
        .then((doc) => {
          let appDataList = doc.data;
          appDataList.forEach((doc) => {
            // Extracting the required details
            if (doc.provider && doc.provider.length > 0) {
              let providerData = doc.provider[0];
              let serviceData =
                providerData.service && providerData.service.length > 0
                  ? providerData.service[0]
                  : null;
              if (serviceData) {
                const serviceInfo = {
                  service: serviceData,
                  provider: providerData.provider,
                };
                // Populate both services and invoiceServices
                this.invoiceServices.push(serviceInfo);
                this.invoiceServices.push({
                  service: {
                    ...serviceData,
                    discountPrice: serviceData.servicePrice,
                    amount: 1,
                  },
                  provider: providerData.provider,
                });
              }
            }
          });
        })
        .catch((error) => console.log(error));
    },
    addService() {
      if (this.providersList && this.servicesList) {
        this.servicesList.amount = 1;
        this.invoiceServices.push({
          service: this.servicesList,
          provider: this.providersList,
        });
      }
    },

    removeService(index) {
      this.invoiceServices.splice(index, 1);
    },

    async getUserInfo(event) {
      let user = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getOneUser",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { userId: event },
      })
        .then((response) => {
          let appData = response.data;
          user = appData;
        })
        .catch((error) => console.log(error));

      this.oneUser = user;
      let addressTemp = this.oneUser.addresses.find(
        (address) => address.default === true
      );
      this.oneUser.defaultAddress =
        addressTemp.AddressLine1 +
        " " +
        addressTemp.AddressLine2 +
        " " +
        addressTemp.City +
        " " +
        addressTemp.Province +
        " " +
        addressTemp.PostalCode +
        " " +
        addressTemp.Country;
    },
    changeNumber(number) {
      this.selectedNumber = number.number;
    },
  },
};
</script>
<style scoped>
.highlight-text {
  font-size: larger;
  font-weight: bold;
  color: red;
}

.marginall {
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 100px;
  margin-left: 100px;
}

.full-width {
  width: 100%;
}

.center {
  text-align: center;
  justify-content: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  /* Center align text in table cells */
}

.line-through {
  text-decoration: line-through;
}

thead {
  background-color: #f0f0f0;
}

.left-aligned {
  text-align: left;
}

.right-aligned {
  text-align: right;
}
</style>
