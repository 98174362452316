<template>
  <div>
    <!-- data table -->
    <v-btn v-if="!displayOnly" @click="newReceiptPage = true,beforePrint=true">New Receipt</v-btn>
    <br />
    <b v-if="!displayOnly">Receipt History</b>
    <v-data-table :headers="receiptHeader" :sort-by="defaultSortBy" :sort-desc="defaultSortDesc" :items="receiptListItems"
      :items-per-page="5" class="elevation-1">
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editReceiptItem(item)">mdi-pencil</v-icon>
      </template>
    </v-data-table>

    <!-- Edit Receipt Section -->
    <v-dialog v-model="editReceiptPage" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Current Payment</span>
          <v-spacer />
          <v-btn icon @click="editReceiptPage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-container>
              Payment Method: {{ selectedPaymentMethod }}
            </v-container>
          </v-card>


          <v-list dense>
            <v-list-item v-for="(service, index) in receiptServices" :key="index" class="mb-2">
              <v-card class="pa-3 full-width" outlined>
                <div>Service: {{ service.service.serviceName }}</div>
                <div>Provider: {{ service.provider.providerName }}</div>
                <div>Tax: {{ service.service.serviceHST ? 'Yes' : 'No' }}</div>
                <br />
                <span>Original Price: {{ service.service.servicePrice }}</span>
                <div class="d-flex align-center">
                  <span>Final Price: {{ service.service.discountPrice }}</span>
                </div>
                <div class="d-flex align-center"><span>Amount: {{ service.amount }}</span>
                </div>
              </v-card>
            </v-list-item>
          </v-list>

          <v-card>
            <v-container>
              Total: {{ this.total.toFixed(2) }}
            </v-container>
          </v-card>

          <br />
          <v-btn v-if="editing" color="primary" @click="printReceiptNoDB">Print Current Receipt</v-btn>
          <br />
          <br />
          <div>
            <!-- <v-row>
              <v-col> <v-btn v-if="editing" color="primary" @click="printReceiptAndEmail">Print Receipt & Send
                  Email</v-btn>
              </v-col>
              <v-col>
                <v-text-field v-model="userEmailToSendReceipt" type="text" label="Patient's Email" solo dense
                  class="ml-2"></v-text-field>
              </v-col>
              <br />
            </v-row> -->
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- new receipt -->
    <v-dialog v-model="newReceiptPage" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <h3>Receipt Management Page</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click="newReceiptPage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="beforePrint">

            <v-list dense>
              <v-list-item v-for="(service, index) in receiptServices" :key="index" class="mb-2">
                <v-card class="pa-3 full-width" outlined>
                  <div>Service: {{ service.service.serviceName }}</div>
                  <div>Provider: {{ service.provider.providerName }}</div>
                  <div>Tax: {{ service.service.serviceHST ? 'Yes' : 'No' }}</div>
                  <br />
                  <span>Original Price: {{ service.service.servicePrice }}</span>
                  <div class="d-flex align-center">
                    <span>Final Price: </span>
                    <v-text-field v-model="service.service.discountPrice" @input="updateDiscountPrice(index, $event)"
                      type="number" label="Unit Price" solo dense class="ml-2"></v-text-field>
                  </div>
                  <div class="d-flex align-center"><span>Amount: </span>
                    <v-text-field v-model="service.amount" @input="updateAmount(index, $event)" type="text" label="Amount"
                      solo dense class="ml-2">
                    </v-text-field>
                  </div>
                  <v-btn icon small color="red" @click="removeReceiptService(index)" class="mt-2">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card>
              </v-list-item>
            </v-list>
            <v-card  style="background-color: #f0fff3;">
              <v-container>
                <v-row>
                  <v-col>
                    <providerSelector @providerSelected="onProviderSelected" :locationId="locationId" :key="locationId" />
                  </v-col>
                  <v-col>
                    <serviceSelector @serviceSelected="onServiceSelected" :providerId="providerId"
                      :locationId="locationId" :key="providerId + '-' + locationId" />
                  </v-col>
                </v-row>

                {{ timeSlotLengthToDisplay }}
                <v-btn @click="addReceiptService" text small>Add This Service<v-icon>mdi-plus</v-icon></v-btn>
              </v-container>
            </v-card>
            <br />
            <v-card  style="background-color: #f0fff3;">
              <v-container>
                <v-text-field v-model="balance" label="Balance" type="number"></v-text-field>
                <v-select label="Payment Method" :items="paymentMethods" v-model="selectedPaymentMethod"></v-select>
              </v-container>
            </v-card>
            <br />
            <v-btn color="primary" :disabled="!selectedPaymentMethod" @click="saveReceipt">Save to Database</v-btn>
          </div>
          <div v-else>
            <v-card>
              <v-card-text>
                <v-card>
                  <v-container>
                    Payment Method: {{ selectedPaymentMethod }}
                  </v-container>
                </v-card>
                <v-list dense>
                  <v-list-item v-for="(service, index) in receiptServices" :key="index" class="mb-2">
                    <v-card class="pa-3 full-width" outlined>
                      <div>Service: {{ service.service.serviceName }}</div>
                      <div>Provider: {{ service.provider.providerName }}</div>
                      <div>Tax: {{ service.service.serviceHST ? 'Yes' : 'No' }}</div>
                      <br />
                      <span>Original Price: {{ service.service.servicePrice }}</span>
                      <div class="d-flex align-center">
                        <span>Final Price: {{ service.service.discountPrice }}</span>
                      </div>
                      <div class="d-flex align-center"><span>Amount: {{ service.amount }}</span>
                      </div>
                    </v-card>
                  </v-list-item>
                </v-list>
                <v-card>
                  <v-container>
                    Total: {{ this.total.toFixed(2) }}
                  </v-container>
                </v-card>
                <br />
                <v-btn color="primary" @click="printReceiptNoDB">Print Current Receipt</v-btn>
                <br />
                <br />

                <!-- <v-row>
                  <v-col>
                    <v-btn color="primary" @click="printReceiptAndEmail">Email Receipt</v-btn>
                    <br />
                  </v-col>
                  <v-col>
                    <v-text-field v-model="userEmailToSendReceipt" type="text" label="Patient's Email" solo dense
                      class="ml-2"></v-text-field>
                  </v-col>
                </v-row> -->
              </v-card-text>
            </v-card>

          </div>
          <br />
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- receipt pdf -->
    <VueHtml2pdf @close="closePage" :show-layout="false" :float-layout="true" :enable-download="false"
      :preview-modal="true" :paginate-elements-by-height="1400" filename="Receipt" :pdf-quality="2"
      :manual-pagination="false" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px"
      @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)" ref="receiptPDF">
      <section slot="pdf-content" class="marginall">
        <h1 class="center">Receipt</h1>
        <br />
        <!-- Centered Address -->
        <div class="center">
          <p v-if="location">{{ this.location.location }}</p>
        </div>
        <!-- Right Aligned Receipt Number -->
        <div class="right-aligned">
          <p>Receipt Number: {{ receiptNumber }}</p>
        </div>
        <!-- Left Aligned Details -->
        <div class="left-aligned">
          <p>Issue Date: {{ new Date(this.selectedEvent.start).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }) }}</p>
          <p>Bill To: {{ this.oneUser && this.oneUser.basicInfo ? this.oneUser.basicInfo.first_name + ' ' +
            this.oneUser.basicInfo.last_name : 'N/A' }} </p>
          <p>Address: {{ this.oneUser ? this.oneUser.defaultAddress : 'N/A' }}</p>
          <p>Phone: {{ this.oneUser ? this.oneUser.defaultPhoneNumber : 'N/A' }}</p>
          <p>Email: {{ this.oneUser ? this.oneUser.defaultEmail : 'N/A' }}</p>
        </div>
        <br />
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Unit Price ($)</th>
              <th>Amount</th>
              <th>Price ($)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(service, index) in receiptServices" :key="index">
              <td>{{ service.service.serviceName }}</td>
              <td>{{ parseFloat(service.service.servicePrice).toFixed(2) }}</td>
              <td>{{ service.amount }}</td>
              <td v-if="service.service.discountPrice == service.service.servicePrice">
                {{
                  (service.service.servicePrice * service.amount).toFixed(2)
                }}
              </td>
              <td v-else>
                <v-row>
                  <v-col>
                    <div :class="{ 'line-through': isDiscounted(service) }">
                      {{ formatPrice(service.service.servicePrice * service.amount) }}
                    </div>
                  </v-col>
                  <v-col>
                    <div v-if="isDiscounted(service)" class="highlight-discount">
                      {{ formatPrice(service.service.discountPrice * service.amount) }}
                    </div>
                  </v-col>
                </v-row>
              </td>


            </tr>
          </tbody>
        </table>
        <br />
        <div class="right-aligned">
          <p>SubTotal: {{ subTotal.toFixed(2) }}</p>
          <p>Tax: {{ hst.toFixed(2) }}</p>
          <p>Total: {{ total.toFixed(2) }}</p>
          <p class="highlight-discount" v-if="difference > 0 && difference != total.toFixed(2)"
            :class="{ 'text-danger': difference > 0 }">
            You Saved: {{ difference.toFixed(2) }}
          </p>
        </div>

        <br />
        <br />
        <div class="left-aligned">
          <p>Thank you!</p>
        </div>

      </section>
    </VueHtml2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    VueHtml2pdf,
  },
  name: "receiptPage",
  props: ["selectedEvent","displayOnly"],
  data: () => ({
    defaultSortBy: 'date',
    defaultSortDesc: true,
    balance: 0,
    isPaid: true,
    beforePrint: true,
    oneUser: null,
    selectedItems: [],
    paymentMethods: ['Cash', 'Credit Card', 'Cheque', 'EMT', 'Gift Card'],
    selectedPaymentMethod: '',
    //new service
    services: [],
    receiptNumber:null,
    editing: false,
    locationId: null,
    providerId: null,
    providersList: null,
    userEmailToSendReceipt: null,
    servicesList: null,
    receiptServices: [],
    newReceiptPage: false,
    editReceiptPage: false,
    location: null,

    receiptHeader: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "date",
      },
      {
        text: "Total Price ($)",
        value: "total",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],

    receiptListItems: [],
    timeSlotLengthToDisplay: " ",

  }),
  async mounted() {
    const storedOption = localStorage.getItem("lOp");
    if (storedOption) {
      this.location = JSON.parse(storedOption);
      this.locationId = this.location.locationId
    }
    this.getAllRelatedEvents()
    this.getUserInfo(this.selectedEvent.UserId)
    this.getReceiptList()
  },
  watch: {

  },
  computed: {

    subTotal() {
      return this.receiptServices.reduce((total, service) => {
        const price = service.service.discountPrice ? service.service.discountPrice : service.service.servicePrice;
        return total + price * service.amount;
      }, 0);
    },
    hst() {
      return this.receiptServices.reduce((total, service) => {
        if (service.service.serviceHST) {
          const price = service.service.discountPrice ? service.service.discountPrice : service.service.servicePrice;
          const taxRate = service.service.hstRate && !isNaN(parseFloat(service.service.hstRate)) ? 1 + parseFloat(service.service.hstRate) / 100 : 1;
          return total + price * service.amount * (taxRate - 1);
        }
        return total;
      }, 0);
    },
    total() {
      return this.subTotal + this.hst
    },
    totalSaved() {

      const originalTotal = this.receiptServices.reduce((total, service) => {
        return total + service.service.servicePrice * service.amount;
      }, 0);
      return originalTotal - this.total;
    },
    difference() {
      const originalTotal = this.receiptServices.reduce((total, service) => {
        const price = service.service.servicePrice * service.amount;
        return total + (service.service.serviceHST ? price * 1.13 : price);
      }, 0);
      return originalTotal - this.total;
    }
  },

  methods: {

    async getReceiptList() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/receiptList/get",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          UserId: this.selectedEvent.UserId
        }
      })
        .then((response) => {
          this.receiptListItems = response.data
          this.receiptListItems.forEach((doc) => {
            doc.date = doc.date.split("T")[0] + " " + doc.date.split("T")[1].split('.')[0]
          })
        })
        .catch((error) => console.log(error));
    },
    isDiscounted(service) {
      // Ensure both values are treated as numbers
      const originalPrice = Number(service.service.servicePrice);
      const discountPrice = Number(service.service.discountPrice);
      return discountPrice < originalPrice;
    },

    formatPrice(value) {
      // Fix any floating-point arithmetic issues and return a formatted string
      return (Math.round(value * 100) / 100).toFixed(2);
    },
    editReceiptItem(data) {
      this.editing = true
      this.selectedPaymentMethod = data.paymentMethod
      this.receiptNumber = data.receiptNumber
      this.receiptServices = data.receiptServices
      this.editReceiptPage = true
    },

    updateDiscountPrice(index, newPrice) {
      // Update the discountPrice for the specified service in receiptServices
      if (!this.receiptServices[index].service.discountPrice) {
        this.$set(this.receiptServices[index].service, 'discountPrice', newPrice);
      } else {
        this.receiptServices[index].service.discountPrice = newPrice;
      }
    },

    closePage() {
      this.$emit('close')
    },

    NewPageFn() {
      this.editing = false
      this.selectedPaymentMethod = ''
      this.receiptServices = []
      this.getAllRelatedEvents()
      this.newReceiptPage = true
    },

    async printReceiptNoDB() {
      this.editReceiptPage = false
      this.$refs.receiptPDF.generatePdf()
    },

    async printReceiptAndEmail() {
      let date = new Date()
      let dataToSave = {
        selectedEvent: this.selectedEvent,
        receiptServices: this.receiptServices,
        date: date,
        paymentMethod: this.selectedPaymentMethod,
        total: this.total.toFixed(2)
      }
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/receiptEmail",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          dataToSave: dataToSave,
          email: this.oneUser.defaultEmail
        }
      })
        .then((response) => {
          if (response.status == 200) {
            this.$refs.receiptPDF.generatePdf()
            this.getReceiptList()
          }
        })
        .catch((error) => console.log(error));
      this.editReceiptPage = false;
    },

    async saveReceipt() {
      let date = new Date()
      let dataToSave = {
        selectedEvent: this.selectedEvent,
        receiptServices: this.receiptServices,
        date: date,
        paymentMethod: this.selectedPaymentMethod,
        total: this.total.toFixed(2),
        balance:this.balance || 0
      }
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/receiptList/add",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          dataToSave: dataToSave
        }
      })
        .then((response) => {
          if (response.status == 200) {
            this.receiptNumber = response.data.formattedReceiptNumber
            this.beforePrint = false
            this.newPaymentPage = false
            this.getReceiptList()
          }
        })
        .catch((error) => console.log(error));
 
    },



    addReceiptService() {
      if (this.servicesList) {
        const newService = {
          ...this.servicesList,
          discountPrice: this.servicesList.servicePrice,
        };

        this.receiptServices.push({
          service: newService,
          provider: this.providersList,
          amount: 1
        });
      }
    },

    updateAmount(index, newAmount) {
      if (this.receiptServices[index]) {
        this.receiptServices[index].amount = newAmount;
      }
    },

    removeReceiptService(index) {
      this.receiptServices.splice(index, 1);
    },

    onProviderSelected(data) {
      this.providerId = data.providerId;
      this.timeSlotLengthToDisplay = ""
      this.providersList = data
    },
    onServiceSelected(data) {
      this.timeSlotLengthToDisplay = this.getTimeLengthDifference(
        data.milliseconds
      );
      this.servicesList = data;
    },
    getTimeLengthDifference(time) {
      let temp = time / 60000;
      let hours = Math.floor(temp / 60);
      let minutes = temp % 60;
      if (hours === 0) {
        return `${minutes} mins`;
      } else if (minutes === 0) {
        return `${hours} hr${hours > 1 ? "s" : ""}`;
      } else {
        return `${hours} hr${hours > 1 ? "s" : ""} ${minutes} mins`;
      }
    },
    async getAllRelatedEvents() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getAllRelatedEvents",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { selectedEvent: this.selectedEvent },
      })
        .then((doc) => {
          let appDataList = doc.data;
          appDataList.forEach((doc) => {
            // Extracting the required details
            if (doc.provider && doc.provider.length > 0) {
              let providerData = doc.provider[0];
              let serviceData = providerData.service && providerData.service.length > 0 ? providerData.service[0] : null;
              if (serviceData) {
                const serviceInfo = {
                  service: serviceData,
                  provider: providerData.provider,
                };
                // Populate both services and receiptServices
                this.services.push(serviceInfo);
                this.receiptServices.push({
                  service: { ...serviceData, discountPrice: serviceData.servicePrice },
                  provider: providerData.provider,
                  amount: 1
                });
              }
            }
          })
        }).catch((error) => console.log(error));
    },
    addService() {
      if (this.providersList && this.servicesList) {
        this.services.push({
          service: this.servicesList,
          provider: this.providersList,
        });
      }
    },

    removeService(index) {
      this.services.splice(index, 1);
    },

    async getUserInfo(event) {
      let user = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getOneUser",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { userId: event },
      })
        .then((response) => {
          let appData = response.data;
          this.userEmailToSendReceipt = appData.defaultEmail
          user = appData;
        })
        .catch((error) => console.log(error));

      this.oneUser = user;
      let addressTemp = this.oneUser.addresses.find(address => address.default === true);
      this.oneUser.defaultAddress = addressTemp.AddressLine1 + ' ' + addressTemp.AddressLine2 + ' ' + addressTemp.City + ' ' + addressTemp.Province + ' ' + addressTemp.PostalCode + ' ' + addressTemp.Country

    },

  },
};
</script>
<style scoped>
.highlight-text {
  font-size: larger;
  font-weight: bold;
  color: red;
}

.marginall {
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 100px;
  margin-left: 100px;
}

.full-width {
  width: 100%;
}

.center {
  text-align: center;
  justify-content: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  /* Center align text in table cells */
}



.line-through {
  text-decoration: line-through;
}

.highlight-discount {
  color: red;
  font-weight: bold;
  margin-left: 8px;
  /* Adjust as needed for spacing */
}

thead {
  background-color: #f0f0f0;
}

.left-aligned {
  text-align: left;
}

.right-aligned {
  text-align: right;
}
</style>
