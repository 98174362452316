<template>
  <v-app>
    <v-main>
      <v-container>

        <!-- Intake Page -->
        <v-row>
          <!-- Left Part: Dropdowns for Body Parts -->
          <v-col cols="6">
            <v-card>
              <v-card-title>Your Major Concern on Your Health</v-card-title>
              <v-card-text>
                <v-autocomplete v-model="search" :items="items" label="Search Health Concerns"
                  @change="filterItems"></v-autocomplete>
                <v-expansion-panels v-model="panel">
                  <v-expansion-panel v-for="(item, i) in filteredItems" :key="i">
                    <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-checkbox v-for="(option, index) in item.options" :key="index" :label="option" :value="option"
                        @change="handleOptionChange(item.title, option)"></v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Right Part: Buttons for Each Category -->
          <!-- Right Part: Buttons for Each Category or Sub-options -->
          <v-col cols="6">

            <v-card>
              <v-card-title>
                <v-btn v-if="showSubOptions" icon @click="goBack">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                {{ showSubOptions ? 'Options in ' + currentCategory : 'Select Category for Detailed Questions' }}
              </v-card-title>
              <v-card-text>
                
          <!-- Main Categories -->
          <div>
            <span v-for="(crumb, index) in breadcrumbPath" :key="index">
              <v-btn text small @click="index === 0 ? goBackToMain() : null">{{ crumb }}</v-btn>
              <span v-if="index < breadcrumbPath.length - 1"> > </span>
            </span>
          </div>
          <v-row v-if="currentLevel === 'main'">
            <v-col cols="12">
              <div class="scrollable-slideshow">
                <v-row no-gutters class="align-center">
                  <v-col v-for="(category, index) in categories" :key="index" cols="auto" class="slideshow-item">
                    <v-card :class="getCardClass(index)" :style="getCardStyle(index)" @mouseover="hoveringOver(index)"
                      @mouseleave="leavingHover()" @click="selectCategory(category)">
                      <v-img :src="getCategoryImage(category)" height="200px"></v-img>
                      <v-card-title>{{ category }}</v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="currentLevel === 'sub'">
            <v-col cols="12">
              <div class="scrollable-slideshow">
                <v-row no-gutters class="align-center">
                  <v-col v-for="(subOption, index) in currentSubOptions" :key="index" cols="auto" class="slideshow-item">
                    <v-card :class="{ 'hovered': hoveredSubIndex === index }" @mouseover="hoveringOverSub(index)"
                      @mouseleave="leavingHoverSub()" @click="selectSubOption(subOption.name)">
                      <v-img :src="subOption.imageUrl" height="200px"></v-img>
                      <v-card-title>{{ subOption.name }}</v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>




                
              </v-card-text>
            </v-card>
          </v-col>







        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn color="primary" @click="openDialog()">Get Questions</v-btn>
          </v-col>
        </v-row>


        <v-dialog v-model="showResults" max-width="600px">
          <v-card>
            <v-card-title>Your Answers</v-card-title>
            <v-card-text>
              <div v-for="(answer, index) in answers" :key="index">
                <h3>{{ questions[index].question }}</h3>
                <p>Answer: {{ answer }}</p>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="showResults = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>



        <!-- Pop-up Dialog for Questions -->
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              {{ dialogTitle }}
              <v-spacer></v-spacer> <!-- This pushes the button to the right -->
              <v-btn icon @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <!-- Questions in Dialog -->
              <div v-if="currentQuestion">
                <!-- Multiple Choice Question -->
                <div v-if="currentQuestion.type === 'multiple-choice'">
                  <div>{{ currentQuestion.question }}</div>
                  <v-radio-group v-model="answers[currentQuestionIndex]">
                    <v-radio v-for="(choice, index) in currentQuestion.choices" :key="index" :label="choice"
                      :value="choice"></v-radio>
                  </v-radio-group>
                </div>
                <div v-if="currentQuestion.type === 'rating'">
                  <div>{{ currentQuestion.question }}</div>
                  <v-slider v-model="answers[currentQuestionIndex]" :min="currentQuestion.min" :max="currentQuestion.max"
                    thumb-label thumb-color="blue"></v-slider>
                </div>
                <div v-if="currentQuestion.type === 'checkbox'">
                  <div>{{ currentQuestion.question }}</div>
                  <v-checkbox v-for="(option, index) in currentQuestion.options" :key="index" :label="option"
                    v-model="answers[currentQuestionIndex][option]"></v-checkbox>
                </div>
                <div v-if="currentQuestion.type === 'input'">
                  <div>{{ currentQuestion.question }}</div>
                  <v-text-field v-model="answers[currentQuestionIndex]"
                    :placeholder="currentQuestion.placeholder || ''"></v-text-field>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <!-- Previous Button -->
              <v-btn @click="goToPrevious" :disabled="currentQuestionIndex === 0">Previous</v-btn>

              <!-- Next Button: Hidden if it's the last question -->
              <v-btn @click="goToNext" :disabled="currentQuestionIndex >= questions.length - 1" v-if="!isLastQuestion">
                Next
              </v-btn>

              <!-- Submit Button: Visible only on the last question -->
              <v-btn @click="submit" color="primary" v-if="isLastQuestion">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
export default {
  data: () => ({
    currentLevel: 'main',
    breadcrumbPath: ['All'],
    hoveredSubIndex: null,
    hoveredIndex: null,
    showSubOptions: false,
    currentCategory: null,
    currentQuestionIndex: 0,
    answers: {},
    questions: [], // To be populated based on category selection
    historyStack: [],
    currentSubOptions: [],
    showResults: false,
    selectedOptions: {},
    // Data for intake page (dropdowns)
    search: '',
    items: [
      {
        title: 'Head',
        options: ['Headache', 'Dizziness', 'Hair Issues', 'Five Senses', 'No Concerns']
      },
      {
        title: 'Neck',
        options: ['Stiffness', 'Pain', 'Swelling', 'No Concerns']
      },
      {
        title: 'Arms',
        options: ['Weakness', 'Pain', 'Numbness', 'Swelling', 'No Concerns']
      },
      {
        title: 'Legs',
        options: ['Cramps', 'Swelling', 'Pain', 'Numbness', 'No Concerns']
      },
      {
        title: 'Back',
        options: ['Lower Back Pain', 'Upper Back Pain', 'Stiffness', 'No Concerns']
      },
      {
        title: 'Front Trunk',
        options: ['Chest Pain', 'Abdominal Pain', 'Heartburn', 'No Concerns']
      },
      {
        title: 'Side Body',
        options: ['Side Pain', 'Tenderness', 'Swelling', 'No Concerns']
      },
      {
        title: 'Stomach',
        options: ['Pain', 'Acid Reflux', 'Nausea', 'Bloating', 'No Concerns']
      }
    ],
    filteredItems: [],
    panel: null,
    categories: ['Head', 'Neck', 'Arms', 'Legs', 'Back', 'Front Trunk', 'Side Body', 'Stomach'],
    dialog: false,
    dialogTitle: '',
    questionsMap: {
      'Head': {
        'Headache': [
          {
            type: 'rating',
            question: 'Rate your experience from 1 to 10',
            min: 1,
            max: 10
          },
          {
            type: 'checkbox',
            question: 'Select the symptoms you are experiencing:',
            options: ['Nausea', 'Sensitivity to light', 'Blurred vision'],
          },
          {
            type: 'input',
            question: 'Please describe any additional symptoms:',
            placeholder: 'Type here...',
          },
          {
            type: 'multiple-choice',
            question: 'Can you select the location of your headache?',
            choices: ['Forehead', 'Top head', 'Back head/Nape Exterior', 'Temple areas'],
          },

        ],
        'Dizziness': [
          {
            type: 'multiple-choice',
            question: 'Dizziness and vertigo: Can you describe your dizziness?',
            choices: ['Spinning inside head', 'General dizziness'],
          },
          {
            type: 'multiple-choice',
            question: 'What factors trigger your dizziness?',
            choices: ['Cold weather', 'Sunshine/hot weather', 'Humidity/rainy weather', 'Windy weather', 'Insufficient sleep or heavy workload', 'Around menstrual period'],
          }
        ],
        'Hair Issues': [
          {
            type: 'multiple-choice',
            question: 'Select your hair problem:',
            choices: ['Loss of hair', 'Dry hair', 'White/gray hair', 'Hair does not grow', 'Too oily hair', 'Alopecia areata'],
          },
          {
            type: 'multiple-choice',
            question: 'What factors aggravate your hair problem?',
            choices: ['Anxiety/pressure', 'Postpartum', 'Post surgery', 'Disease', 'Environment change', 'Diet change', 'Workplace change', 'No particular reason'],
          },
          {
            type: 'multiple-choice',
            question: 'Where is the hair problem located?',
            choices: ['General', 'Forehead', 'Top head', 'Back head/Nape Exterior', 'Temple areas'],
          }
        ]
      },
      'Neck': {
        'Stiffness': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Neck Stiffness?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Pain': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Neck Pain?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Swelling': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Neck Swelling?',
            choices: ['Pain', 'No concern'],
          }
        ],
      },
      'Arms': {
        'Weakness': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Arm Weakness?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Pain': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Arm Pain?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Numbness': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Arm Numbness?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Swelling': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Arm Swelling?',
            choices: ['Pain', 'No concern'],
          }
        ],
      },
      'Legs': {
        'Cramps': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Leg Cramps?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Swelling': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Leg Swelling?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Pain': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Leg Pain?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Numbness': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Leg Numbness?',
            choices: ['Pain', 'No concern'],
          }
        ],
      },
      'Back': {
        'Lower Back Pain': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Lower Back Pain?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Upper Back Pain': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Upper Back Pain?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Stiffness': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Back Stiffness?',
            choices: ['Pain', 'No concern'],
          }
        ],
      },
      'Front Trunk': {
        'Chest Pain': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Chest Pain?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Abdominal Pain': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Abdominal Pain?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Heartburn': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Heartburn?',
            choices: ['Pain', 'No concern'],
          }
        ],
      },
      'Side Body': {
        'Side Pain': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Side Pain?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Tenderness': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Side Tenderness?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Swelling': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Side Swelling?',
            choices: ['Pain', 'No concern'],
          }
        ],
      },
      'Stomach': {
        'Pain': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Stomach Pain?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Acid Reflux': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Acid Reflux?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Nausea': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Nausea?',
            choices: ['Pain', 'No concern'],
          }
        ],
        'Bloating': [
          {
            type: 'multiple-choice',
            question: 'How do you feel about your Bloating?',
            choices: ['Pain', 'No concern'],
          }
        ],
      },





    },
  }),

  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    isLastQuestion() {
      return this.currentQuestionIndex === this.questions.length - 1;
    },
  },

  methods: {
    selectCategory(category) {
      this.currentCategory = category;
      this.currentLevel = 'sub';
      this.breadcrumbPath = ['All', category];
      this.prepareSubOptions(category);
    },


    goBackToMain() {
      this.currentLevel = 'main';
      this.breadcrumbPath = ['All'];
    },
    handleOptionChange(category, option) {
      if (!this.selectedOptions[category]) {
        this.selectedOptions[category] = [];
      }
      const index = this.selectedOptions[category].indexOf(option);
      if (index === -1) {
        this.selectedOptions[category].push(option);
      } else {
        this.selectedOptions[category].splice(index, 1);
      }
    },

    openDialog() {
      this.dialogTitle = 'Questions Based on Your Selection';

      this.dialog = true;
      this.compileQuestions();
    },

    compileQuestions(category, subOption = null) {
      this.questions = [];
      const optionQuestions = this.questionsMap[category][subOption];
      if (optionQuestions) {
        this.questions = [...optionQuestions];
      }
      this.answers = this.questions.map(q => q.type === 'rating' ? 5 : '');
      this.currentQuestionIndex = 0;
      this.answers = []
    },
    closeDialog() {
      this.dialog = false;
      this.currentQuestionIndex = 0;
      this.answers = [];
      this.questions = [];
      // Reset any other relevant data if needed
    },
    goToNext() {
      this.historyStack.push(this.currentQuestionIndex);
      const currentQuestion = this.questions[this.currentQuestionIndex];
      if (currentQuestion.nextQuestion) {
        const nextIndex = currentQuestion.nextQuestion[this.answers[this.currentQuestionIndex]];
        if (nextIndex !== undefined) {
          this.currentQuestionIndex = nextIndex;
          return;
        }
      }
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
      }
    },

    goToPrevious() {
      if (this.historyStack.length > 0) {
        this.currentQuestionIndex = this.historyStack.pop();
      }
    },

    submit() {
      this.showResults = true;
      this.dialog = false;
      // You can add additional logic here if needed
    },
    goBack() {
      this.showSubOptions = false;
      this.currentCategory = null;
    },

    filterItems() {
      this.filteredItems = this.items.filter(item => {
        return item.title.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    selectSubOption(subOptionName) {
      // Check if questions for the selected sub-option exist
      if (this.questionsMap[this.currentCategory] && this.questionsMap[this.currentCategory][subOptionName]) {
        // Assign the questions to the current questions array
        this.questions = this.questionsMap[this.currentCategory][subOptionName];
        this.currentQuestionIndex = 0;

        // Initialize the answers object
        this.answers = {};
        this.questions.forEach((question, index) => {
          if (question.type === 'checkbox') {
            // For checkbox questions, initialize each option with a false value
            this.answers[index] = {};
            question.options.forEach(option => {
              this.answers[index][option] = false;
            });
          } else if (question.type === 'input') {
            // For input questions, initialize with an empty string
            this.answers[index] = '';
          }
        });

        // Set the dialog title and open the dialog
        this.dialogTitle = 'Questions for ' + subOptionName;
        this.dialog = true;
      } else {
        // Log an error if questions are not found
        console.error('Questions not found for:', subOptionName);
      }
    },



    selectOption(option) {
      this.handleOptionChange(this.currentCategory, option);
      this.openDialog();
    },

    getCardClass(index) {
      return {
        'hovered': this.hoveredIndex === index,
        'non-hovered': this.hoveredIndex !== null && this.hoveredIndex !== index
      };
    },

    getCardStyle(index) {
      if (this.hoveredIndex === index) {
        return {
          transform: 'scale(1.2)',
          zIndex: 100
        };
      }
      return { zIndex: 0 };
    },


    showOptions(category) {
      this.currentCategory = category;
      this.showSubOptions = true;
      this.prepareSubOptions(category);
    },



    getCategoryImage(category) {
      return `/img/categories/${category.toLowerCase()}.jpg`;
    },

    hoveringOver(index) {
      this.hoveredIndex = index;
    },
    hoveringOverSub(index) {
      this.hoveredSubIndex = index;
    },

    leavingHoverSub() {
      this.hoveredSubIndex = null;
    },
    leavingHover() {
      this.hoveredIndex = null;
    },

    prepareSubOptions(category) {
      const categoryData = this.items.find(item => item.title === category);

      if (categoryData) {
        this.currentSubOptions = categoryData.options.map(option => {
          console.log('what is category', option)
          return {
            name: option,
            imageUrl: `/img/subcategories/${category.toLowerCase()}/${option.toLowerCase()}.jpg` // Adjust path as needed
          };
        });
      }
    },

  },

  mounted() {
    console.log("Categories:", this.categories);
    console.log("Items:", this.items);
    this.filteredItems = this.items;
  }
};
</script>

<style scoped> .scrollable-slideshow {
   overflow-x: auto;
   white-space: nowrap;
   padding-top: 40px;
   padding-left: 25px;
   padding-bottom: 20px;
 }

 .slideshow-item {
   transition: transform 0.5s ease;
   display: inline-block;
   width: 200px;
   /* Set a fixed width for each card */
   margin-right: 8px;
   transition: transform 0.5s ease;
   margin-bottom: 20px;
   vertical-align: top;
 }

 .v-card-title {
   font-size: 14px;
   /* Adjust font size as needed */
   white-space: normal;
   /* Allow text to wrap */
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   /* Number of lines to allow before truncating */
   -webkit-box-orient: vertical;
 }

 .scrollable-slideshow {
   overflow-x: auto;
   white-space: nowrap;
 }

 .hovered {
   transform: scale(1.2);
   z-index: 100;
 }

 .slideshow-item {
   display: inline-block;
   margin-right: 8px;
   transition: transform 0.5s ease;
 }
</style>