<template>
    <div class="chatbox-container">
      <div class="container">
        <h1>record your dialog</h1>    
        <button class="lang-toggle-btn" @click="toggleLanguage">{{ language }}</button>
        <v-btn :class="`mic ${isRecording ? 'recording' : ''}`" @click="toggleMic" class="record-button">
          <span v-if="isRecording" color="red">
            <v-icon  color="red">mdi-stop</v-icon>
            Stop
          </span>
          <span color="green" v-else>
            <v-icon  color="green">mdi-microphone</v-icon>
            Record
          </span>
        </v-btn>
        <div class="messageBox mt-8">
          <div class="userMessageWrapper" v-for="(message, index) in recordedText" :key="index">
            <div class="userMessageContent">{{ message }}</div>
            <v-icon @click="editMessage(index)" class="edit-icon">mdi-pencil</v-icon>
          </div>
        </div>

        <div>{{ ongoingTranscript }}</div>
        <textarea v-model="editedMessage" class="edit-textbox"></textarea>
        <v-btn @click="confirmEdit" class="submit-button">Submit</v-btn>
        <v-dialog v-model="confirmDialog" persistent>
          <v-card>
            <v-card-title>Confirmation</v-card-title>
            <v-card-text>Are you sure you want to update this message?</v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="updateMessage">Yes</v-btn>
              <v-btn color="red" @click="cancelEdit">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  const isRecording = ref(false)
  const recordedText = ref([])
  const ongoingTranscript = ref('')
  const editedMessage = ref('')
  let editedIndex = null
  const confirmDialog = ref(false)
  
  const Recognition = window.SpeechRecognition || window.webkitSpeechRecognition
  const sr = new Recognition()
   
sr.lang = "zh-CN";

const language = ref('中文')


  sr.lang = "zh-CN";
  
  onMounted(() => {
    sr.continuous = true
    sr.interimResults = true
  
    sr.onstart = () => {
      console.log('SR Started')
      isRecording.value = true
    }
  
    sr.onend = () => {
      console.log('SR Stopped')
      isRecording.value = false
      if (ongoingTranscript.value.trim() !== '') {
        recordedText.value.push(ongoingTranscript.value.trim())
        ongoingTranscript.value = ''
      }
    }
  
    sr.onresult = (evt) => {
      let interimTranscript = ''
      let finalTranscript = ''
      for (let i = 0; i < evt.results.length; i++) {
        const result = evt.results[i]
        if (result.isFinal) {
          finalTranscript += result[0].transcript
        } else {
          interimTranscript += result[0].transcript
        }
      }
      ongoingTranscript.value = ` ${finalTranscript.trim()}${interimTranscript.trim()}` // Combine both interim and final transcripts
    }
  })
  
  const toggleMic = () => {
    if (isRecording.value) {
      sr.stop()
    } else {
      sr.start()
    }
  }
  
  const editMessage = (index) => {
    editedIndex = index
    editedMessage.value = recordedText.value[index]
  }
  
  const confirmEdit = () => {
    confirmDialog.value = true
  }
  
  const updateMessage = () => {
    if (editedIndex !== null && editedIndex !== undefined) {
      recordedText.value[editedIndex] = editedMessage.value
      editedIndex = null
      editedMessage.value = ''
    }
    confirmDialog.value = false
  }
  
  const cancelEdit = () => {
    confirmDialog.value = false
  }


const toggleLanguage = () => {
  if (sr.lang === "zh-CN") {
    sr.lang = "en-US"
    language.value = 'En'
  } else {
    sr.lang = "zh-CN"
    language.value = '中文'
  }
}
  </script>
  
  <style scoped>
  
  .chatbox-container {
  width: 100%;
  height: 600px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 10px solid #ccc; /* Left border */
  border-right: 10px solid #ccc; /* Right border */
}

.container {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative; /* Ensure proper positioning of absolute elements */
}

.button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Adjust padding as needed */
  position: relative; /* Ensure proper positioning of absolute elements */
}

.button-row {
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 50px;
  color: #000; /* Default color */
}

.lang-toggle-btn.active {
  font-weight: bold;
  /* Add styles for active language */
}
  
.lang-toggle-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000; /* Default color */
}

.lang-toggle-btn.active {
  font-weight: bold;
  /* Add styles for active language */
} 
  
  h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #222;
    padding: 16px;
    margin: 0;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e7e7e7;
  }
  
  .messageBox {
    padding: 16px;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .userMessageWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .edit-icon {
    cursor: pointer;
    margin-left: 8px;
  }
  
  .userMessageContent {
    word-break: break-word;
    padding: 8px 12px;
    border-radius: 18px;
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 1.4;
    background-color: #1877F2;
    color: white;
    max-width: 100%;
  }
  
  .edit-textbox {
    margin-top: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    width: 100%;
    max-width: 100%;
  }
  
  .submit-button {
    margin-top: 8px;
    align-self: flex-end;
  }
  </style>
  